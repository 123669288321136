import { getAllCoachAccounts, getAllCoachAccountsPublic } from '#api/accounts';
import useInfiniteScrollQuery from '#hooks/useInfiniteScrollQuery';
import { useIntersectionObserver } from '#hooks/useIntersectionObserver';
import useScrollRestoration from '#hooks/useScrollRestoration';
import { useUserInfo } from '#providers/UserInfo';
import {
  $coachConditionState,
  ICoachConditionState
} from '#states/atoms/coachCondition';
import { $selectedCoachTagState } from '#states/atoms/tagState';
import { CoachAccount } from '#types/account';
import { IGetAllCoachAccountsProps, IResponse } from '#types/api';
import getGeolocation from '#utils/getGeolocation';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner';
import { PER_PAGE_NUMBER } from 'src/shared/constant';
import { CoachFilter } from './CoachFilter';
import { CoachInfoItem } from './CoachInfoItem';
import { CoachSelectorWrap, GridArea, LoadingWrapper } from './styles';

interface GetCoachListParams {
  pageParam: number;
  lat?: number;
  lng?: number;
}

const COACH_LIST_SCROLL_TOP_KEY = 'coach-list-scroll-top';

export const CoachGridSelector: React.FC = () => {
  const { userInfo } = useUserInfo();
  const coachCondition = useRecoilValue($coachConditionState);
  const selectedCoachTag = useRecoilValue($selectedCoachTagState);
  const [currentLocation, setCurrentLocation] = useState<GeolocationPosition>();
  const [lat, setLat] = useState<number>();
  const [lng, setLng] = useState<number>();

  const { setScrollRestoration } = useScrollRestoration(
    COACH_LIST_SCROLL_TOP_KEY
  );

  const DEFAULT_VALUE = {
    page: 0,
    offset: 0,
    total: 0,
    data: []
  };

  const getCoachList = useCallback(
    async ({
      pageParam,
      lat,
      lng
    }: GetCoachListParams): Promise<IResponse<CoachAccount>> => {
      try {
        const page = pageParam && pageParam - 1;

        const PARAMS = {
          page: page,
          offset: PER_PAGE_NUMBER,
          tag:
            selectedCoachTag.id === 'all' ? undefined : selectedCoachTag.name,
          grade: coachCondition.grade ? 1 : 0,
          reviewCount: coachCondition.reviewCount ? 1 : 0,
          distance: coachCondition.distance ? 1 : 0,
          minPrice: coachCondition.minPrice,
          maxPrice: coachCondition.maxPrice,
          lat,
          lng
        } satisfies IGetAllCoachAccountsProps;

        let res;

        if (userInfo) {
          res = await getAllCoachAccounts(PARAMS);
        } else {
          res = await getAllCoachAccountsPublic(PARAMS);
        }

        if (res) {
          return res as IResponse<CoachAccount>;
        } else {
          return DEFAULT_VALUE as IResponse<CoachAccount>;
        }
      } catch (e) {
        return DEFAULT_VALUE as IResponse<CoachAccount>;
      }
    },
    [lat, lng, selectedCoachTag.name, coachCondition]
  );

  const {
    data: coachesList,
    isLoading,
    fetchNextPage,
    hasNextPage
  } = useInfiniteScrollQuery<CoachAccount, ICoachConditionState>({
    perPageNum: PER_PAGE_NUMBER,
    filters: { tag: selectedCoachTag.name, filter: coachCondition }, // 필터링 경우 추가
    queryKeyParam: [lat, lng],
    queryKeyBase: 'getCoach',
    queryFn: ({ pageParam = 1 }) => {
      return getCoachList({ pageParam, lat, lng });
    }
  });

  const { setTarget } = useIntersectionObserver({
    hasNextPage,
    fetchNextPage
  });

  useEffect(() => {
    const getCurrentLocation = async () => {
      try {
        const position = await getGeolocation();
        setCurrentLocation(position);
        setLng(position.coords.longitude);
        setLat(position.coords.latitude);
      } catch (e) {
        console.log(e);
      }
    };

    getCurrentLocation();
  }, []);

  useEffect(() => {
    if (!currentLocation) {
      setLat(userInfo?.lat);
      setLng(userInfo?.long);
    }
  }, [userInfo]);

  return (
    <CoachSelectorWrap>
      <CoachFilter />
      {isLoading ? (
        <LoadingWrapper>
          <LoadingSpinner />
        </LoadingWrapper>
      ) : (
        <GridArea>
          {coachesList?.map(coach => (
            <CoachInfoItem
              key={coach.id}
              data={coach}
              onClick={setScrollRestoration}
            />
          ))}
        </GridArea>
      )}
      <div ref={setTarget} style={{ height: '5px' }} />
    </CoachSelectorWrap>
  );
};
