export const PATH = {
  HOME: '/home',
  HOME_INTRODUCTION: '/home-introduction',
  PROFILE: '/profile',
  PROFILE_UPDATE: '/profile/update',
  PROFILE_SCHEDULE: '/profile/schedule',
  PROFILE_GYM: '/profile/gym',
  PROFILE_CLASS: '/profile/class',
  PROFILE_CLASS_DETAIL: '/profile/class/detail',
  PROFILE_CLASS_PRICE: '/profile/class/price',
  PROFILE_CLASS_FORM: '/profile/class/form',
  PROFILE_OT: '/profile/ot',
  PROFILE_CATEGORY: '/profile/category',
  PROFILE_INTRODUCE: '/profile/introduce',
  PROFILE_SHORT_INTRODUCE: '/profile/short-introduce',
  PROFILE_CERTIFICATE: '/profile/certificate',
  PROFILE_CARRER: '/profile/carrer',
  PROFILE_LECTURE: '/profile/lecture',
  PROFILE_CLINICAL_DATA: '/profile/clinical-data',
  PROFILE_CLINICAL_DATA_FORM: '/profile/clinical-data/form',
  PROFILE_DEGREE: '/profile/degree',
  PROFILE_GYM_REGISTRATION: '/profile/gym-registration',
  DASHBOARD: '/dashboard',
  DASHBOARD_CUSTOMER: '/dashboard/customer',
  DASHBOARD_CONFIRMATION: '/dashboard/customer/confirmation',
  DASHBOARD_CHART: '/dashboard/chart',
  DASHBOARD_CHART_FORM: '/dashboard/chart/form',
  ABOUT: '/about',
  ABOUT_SETTINGS: '/about/settings',
  ABOUT_SETTINGS_ALERT: '/about/settings/alert',
  CHAT: '/chat',
  CHAT_ROOM: '/chat/room',
  PAYMENT: '/application/payment',
  PAYMENT_SUCCESS: '/application/payment-success',
  SUCCESS: '/application/success',
  APPLICATION: '/application',
  APPLICATION_SUCCESS: '/application/success',
  APPLICATION_SCHEDULE_FAIL: '/application/schedule-fail',
  ABOUT_QR: '/about/qr',
  ABOUT_HELP: '/about/help',
  POLICY: '/policy',
  POLICY_SERVICE: '/policy/service',
  POLICY_PRIVACY: '/policy/privacy',
  WELCOME: '/welcome',
  WELCOME_CONSUMER: '/welcome/consumer',
  WELCOME_COACH: '/welcome/coach',
  WELCOME_SCHEDULE_RESERVE: '/welcome/schedule-reserve',
  WELCOME_SCHEDULE_RESERVE_FAIL: '/welcome/schedule-reserve/fail',
  DELETE_ACCOUNT: '/deleteaccount',
  DELETE_ACCOUNT_PRECAUTIONS: '/deleteaccount/1',
  DELETE_ACCOUNT_EXPLANATION: '/deleteaccount/2',
  HELP: '/help',
  HELP_OPINION: '/help/opinion',
  FINAL_RATING: '/final-rating',
  FINAL_RATING_SUCCESS: '/final-rating-success',
  MYPAGE: '/mypage',
  MYPAGE_PROFILE: '/mypage/profile',
  MYPAGE_PROFILE_NICKNAME: '/mypage/profile/nickname',
  MYPAGE_PROFILE_NAME: '/mypage/profile/name',
  MYPAGE_WITHDRAWAL_PRECAUTION: '/mypage/withdrawal/precaution',
  MYPAGE_WITHDRAWAL_EXPLANATION: '/mypage/withdrawal/explanation',
  MYPAGE_APPLICATIONS: '/mypage/applications',
  MYPAGE_APPLICATIONS_CARRER: '/mypage/applications/carrer',
  MYPAGE_APPLICATIONS_PURPOSE: '/mypage/applications/purpose',
  MYPAGE_APPLICATIONS_SLEEP: '/mypage/applications/sleep',
  MYPAGE_APPLICATIONS_JOB: '/mypage/applications/job',
  MYPAGE_APPLICATIONS_HEALTH: '/mypage/applications/health',
  MYPAGE_APPLICATIONS_DETAIL: '/mypage/applications/detail',
  MYPAGE_CONFIRMS: '/mypage/confirms',
  MYPAGE_SCHEDULES: '/mypage/schedules',
  MYPAGE_SCHEDULES_REGISTRATION: '/mypage/schedules/registration',
  MYPAGE_SCHEDULES_COACH: '/mypage/schedules-coach',
  MYPAGE_SETTING: '/mypage/setting',
  MYPAGE_SETTING_ALERT: '/mypage/setting/alert',
  MYPAGE_HELP: '/mypage/help',
  MYPAGE_HELP_CS: '/mypage/help/cs',
  MYPAGE_POLICY: '/mypage/policy',
  MYPAGE_POLICY_SERVICE: '/mypage/policy/service',
  MYPAGE_POLICY_PRIVACY: '/mypage/policy/privacy',
  MYPAGE_POLICY_MARKETING: '/mypage/policy/marketing',
  MYPAGE_MYURL: '/mypage/my-url',
  SIGN: '/sign',
  SIGN_ALARM: '/sign/alarm',
  SIGNIN: '/sign/in',
  SIGNIN_PASSWORD_REQUEST: '/sign/in/password/request',
  SIGNIN_PASSWORD_RESET: '/sign/in/password/reset',
  SIGNUP: '/sign/up',
  SIGN_UP_CATEGORY: '/sign/up/category',
  SIGN_UP_POLICY: '/sign/up/policy',
  SIGN_UP_PHONE: '/sign/up/phone',
  SIGN_UP_ID: '/sign/up/id',
  SIGN_UP_PASSWORD: '/sign/up/password',
  SIGN_UP_NICKNAME: '/sign/up/nickname',
  SIGN_UP_GENDER: '/sign/up/gender',
  SIGN_UP_BIRTHDAY: '/sign/up/birthday',
  SIGN_UP_FINAL: '/sign/up/final',
  GYM_LOCATION: '/gym-location',
  CHART_LECTURE: '/chart/lecture',
  CHART_RECORD: '/chart/record',
  BILLING: '/billing',
  BILLING_REGISTRATION: '/billing/registration',
  BILLING_REGISTRATION_RRN: '/billing/registration/rrn',
  BILLING_REGISTRATION_ACCOUNT: '/billing/registration/account',
  BILLING_DETAIL: '/billing/detail',
  BILLING_REQUEST: '/billing/request',
  BILLING_REQUEST_INVOICE: '/billing/request/invoice',
  BILLING_REQUEST_SUCCESS: '/billing/request/success',
  CALENDER: '/calender',
  CALENDER_DETAIL: '/calender/detail',
  Weekly_Calender_Schedule_Registration: '/calender/schedule-registration',
  WEEKLY_CALENDER_SEARCH_CLIENT: '/calender/search-client',
  WEEKLY_CALENDER_SEARCH_LECTURE: '/calender/search-lecture',
  WEEKLY_CALENDER_REPEAT: '/calender/repeat-lecture',
  TEAM: '/team'
};
