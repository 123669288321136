import intro_chart from '#assets/img/intro_chart.png';
import intro_chat from '#assets/img/intro_chat.png';
import intro_price from '#assets/img/intro_price.png';
import intro_pt from '#assets/img/intro_pt.png';
import intro_schedule from '#assets/img/intro_schedule.png';
import { BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { FibudContainerTemplate } from '#templates';
import { useNavigate } from 'react-router';
// import { PrevHeaderBarTemplate } from '#templates';
import MetaHelmet from '#utils/MetaHelmet';
import {
  BoxContainer,
  Container,
  PageContainer,
  TitleContainer
} from './styles';

export const MainIntroductionPage = () => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <MetaHelmet title="소개" />
      <FibudContainerTemplate>
        <Container>
          {/* <TitleContainer>
            <Text>피벗이 다른 이유</Text>
            <Text className="display">Fibud Exclusive.</Text>
          </TitleContainer> */}
          <BoxContainer>
            <BoxIcon type="check-point-circle" size={106} onlyIcon={true} />
            <Text textStyle="t2">내가 찾던 트레이너</Text>
            <div>
              <div data-text-type="t3" data-text-color="gray2">
                <Text>제대로 된 트레이너를 찾고 계신가요?</Text>
                <Text type="inline">걱정하지 마세요. 피벗에서는 </Text>
                <Text type="inline" textColor="white">
                  높은 전문성과 풍부한 경험을 갖춘 전문가
                </Text>
                <Text type="inline">만 제공하고 있습니다.</Text>
              </div>
              <div
                data-text-type="t3"
                data-text-color="gray2"
                className="word-break"
              >
                <Text type="inline">
                  이제, 피벗에서 운동, 재활 등 체육 학위 보유자, 물리치료사 등
                  국가 공인 면허 보유자, 경력 5년 이상이면서 포트폴리오를 보유한{' '}
                </Text>
                <Text type="inline" textColor="white">
                  상위 5% 전문가
                </Text>
                <Text type="inline">들을 만나보세요.</Text>
              </div>
            </div>
          </BoxContainer>
          <BoxContainer className="top-attached">
            <img src={intro_chat} width={148} />
            <Text textStyle="t2">자유로운 1:1 채팅</Text>
            <div>
              <div
                data-text-type="t3"
                data-text-color="gray2"
                className="word-break"
              >
                <Text>PT를 받기 전, 궁금한 점이 있으신가요?</Text>
                <Text type="inline">걱정하지 마세요. 피벗에서는 </Text>
                <Text type="inline" textColor="white">
                  결제하지 않아도 전문가와의 1:1 채팅을 통해 궁금한 점들을
                  자유롭게 묻고 답변
                </Text>
                <Text type="inline"> 받을 수 있습니다.</Text>
              </div>
              <div data-text-type="t3" data-text-color="gray2">
                <Text>
                  이제, 피벗에서 연락처를 공유하거나 불필요한 카톡 연락 없이
                  전문가와 자유롭게 소통해 보세요.
                </Text>
              </div>
            </div>
          </BoxContainer>
          <BoxContainer>
            <img src={intro_pt} width={270} />
            <Text textStyle="t2">최대 3회 PT 수강권</Text>
            <div>
              <div
                data-text-type="t3"
                data-text-color="gray2"
                className="word-break"
              >
                <Text>
                  나와 맞는 전문가인지, 만나서 충분히 확인해보고 싶으신가요?
                </Text>
                <Text type="inline">
                  걱정하지 마세요. 피벗에서는 많은 회차를 결제할 필요 없이,{' '}
                </Text>
                <Text type="inline" textColor="white">
                  최대 3회의 PT 수강권을 제공
                </Text>
                <Text type="inline">하고 있습니다.</Text>
              </div>
              <div data-text-type="t3" data-text-color="gray2">
                <Text type="inline">이제, 피벗에서 </Text>
                <Text type="inline" textColor="white">
                  내 마지막 전문가
                </Text>
                <Text type="inline">를 찾아보세요.</Text>
              </div>
            </div>
          </BoxContainer>
          <BoxContainer className="top-attached">
            <img src={intro_chart} width={148} />
            <Text textStyle="t2">체계적인 PT 진행 과정 관리</Text>
            <div>
              <div
                data-text-type="t3"
                data-text-color="gray2"
                className="word-break"
              >
                <Text>PT를 통해 배운 내용을 까먹으셨나요?</Text>
                <Text type="inline">
                  걱정하지 마세요. 피벗에서는 운동 차트 시스템을 통해{' '}
                </Text>
                <Text type="inline" textColor="white">
                  매 회차마다 운동 기록, 사진, 영상과 전문가의 피드백을 제공
                </Text>
                <Text type="inline">하고 있습니다.</Text>
              </div>
              <div
                data-text-type="t3"
                data-text-color="gray2"
                className="word-break"
              >
                <Text>
                  운동 차트는 언제든지 원할 때 다시 확인할 수 있고, 전문가가
                  바뀌더라도 운동 차트를 공유하여 연속성 있는 PT를 받을 수
                  있습니다.
                </Text>
              </div>
              <div data-text-type="t3" data-text-color="gray2">
                <Text type="inline">이제, 피벗에서 </Text>
                <Text type="inline" textColor="white">
                  나만의 운동 포트폴리오
                </Text>
                <Text type="inline">를 만들어 보세요.</Text>
              </div>
            </div>
          </BoxContainer>
          <BoxContainer className="top-attached">
            <img src={intro_schedule} width={148} />
            <Text textStyle="t2">세상 편한 수업 예약 및 취소</Text>
            <div>
              <div
                data-text-type="t3"
                data-text-color="gray2"
                className="word-break"
              >
                <Text>밤 늦게 수업을 예약하거나 취소할 일이 생기셨나요?</Text>
                <Text type="inline">
                  걱정하지 마세요. 피벗에서는 전문가에게 연락할 필요 없이{' '}
                </Text>
                <Text type="inline" textColor="white">
                  직접 전문가의 일정을 확인하고 수업을 예약
                </Text>
                <Text type="inline">
                  할 수 있으며, 취소까지 몇 번의 터치 만으로 가능합니다.
                </Text>
              </div>
              <div data-text-type="t3" data-text-color="gray2">
                <Text>
                  이제, 피벗에서 수업 일정 때문에 스트레스 받지 마세요.
                </Text>
              </div>
            </div>
          </BoxContainer>
          <BoxContainer>
            <img src={intro_price} width={169} />
            <Text textStyle="t2">위약금 걱정 없는 환불</Text>
            <div>
              <div
                data-text-type="t3"
                data-text-color="gray2"
                className="word-break"
              >
                <Text>PT를 환불해야하는 상황이 되셨나요?</Text>
                <Text type="inline">걱정하지 마세요. 피벗에서는 </Text>
                <Text type="inline" textColor="white">
                  미사용 PT 수강권에 대해 위약금 없는 100% 환불
                </Text>
                <Text type="inline">을 보장하고 있습니다.</Text>
              </div>
              <div data-text-type="t3" data-text-color="gray2">
                <Text>
                  이제, 피벗에서 환불 걱정 없이 건강한 몸을 만드는데에만 집중해
                  보세요.
                </Text>
              </div>
            </div>
          </BoxContainer>
          <TitleContainer data-text-type="t3">
            <Text textColor="gray2">이제, 피벗에서 한 차원 높은 수준의</Text>
            <div>
              <Text type="inline">진짜 퍼스널 트레이닝</Text>
              <Text type="inline" textColor="gray2">
                을 경험해 보세요.
              </Text>
            </div>
          </TitleContainer>
          <Button onClick={() => navigate(`${PATH.HOME}?tab=2`)}>
            내 주변 전문가 찾기
          </Button>
        </Container>
      </FibudContainerTemplate>
    </PageContainer>
  );
};
