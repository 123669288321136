import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const TitleRightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
`;

export const MyInfoNavigator = styled.span`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: var(--color-point);

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: 'MY';
    color: var(--color-white);
    text-align: center;
    transform: translate(-50%, -50%);
  }
`;
