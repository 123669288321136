import { Route, Routes } from 'react-router';
import { LectureDetailPage, RecordDetailPage } from '#pages';
import LectureChart from '#providers/LectureChart';

export function ChartView() {
  return (
    <LectureChart>
      <Routes>
        <Route path="lecture" element={<LectureDetailPage />} />
        <Route path="record" element={<RecordDetailPage />} />
      </Routes>
    </LectureChart>
  );
}
