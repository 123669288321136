import styled from 'styled-components';

export const ProfileViewerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const ImageList = styled.ul`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
`;

export const ImageItem = styled.li`
  flex-shrink: 0;
  scroll-snap-align: start;
  width: 100%;
  aspect-ratio: 1;
  position: relative;

  &.alt {
    background-color: var(--color-gray-second);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const IndexIconArea = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.25rem;
`;

export const IndexIcon = styled.div`
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  background-color: var(--color-gray-second);

  &.point {
    width: 1rem;
    border-radius: 0.5rem;
    background-color: var(--color-white);
  }
`;
