import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const TopArticle = styled.article`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 5.8rem;
  padding: 1rem 2rem 0;
  background-color: var(--color-background);
  z-index: 10; // 가장 상위 쌓임맥락(z-index)
  max-width: 500px; // 전체 레이아웃 적용
  margin: 0 auto;
`;
//   padding: 5rem 2rem 0;
export const TitleConatiner = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 5.8rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-background);

  & > div.center {
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ContentArticle = styled.article`
  position: relative;
  width: 100%;
  min-height: 100dvh;
  padding-top: 5.8rem;
`;
