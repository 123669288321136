import { useGetAssociationDetailWithUrlQuery } from '#hooks/quries/association/useGetAssociationDetailWithUrlQuery';
import { useGetAssociationCoachesWithUrlQuery } from '#hooks/quries/association/useGetAssociationsCoachesWithUrlQuery';
import { useGeolocation } from '#hooks/useGeolocation';
import { useIntersectionObserver } from '#hooks/useIntersectionObserver';
import useScrollRestoration from '#hooks/useScrollRestoration';
import { LocationItem, TabListViewer } from '#molecules';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { Association } from '#types/associations';
import MetaHelmet from '#utils/MetaHelmet';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner';
import { TabIntroduce } from 'src/components/molecules/TabIntroduce';
import { CoacheList } from 'src/components/organisims/CoacheList/CoacheList';
import { ProfileViewer } from 'src/components/templates/CoachDetailContent/ProfileViewer';

/**
 * @recycleComponent PrevHeaderBarWithTitleTemplate, ProfileViewer, CoachesList
 * @api 1. useGetAssociationIdQuery
 * @api 2. useGetAssociationCoachesQuery
 * @api 2. useGetAssociationDetailQuery
 * @returns
 */
export const CoachAssociationPage = () => {
  const { association } = useParams();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();

  const { data: associationDetail, isLoading: isAssociationDetailLoading } =
    useGetAssociationDetailWithUrlQuery({
      associationUrl: association ?? ''
    });

  const onTabChange = useCallback((tab: number) => {
    setCurrentTab(tab);
    const searchParams = new URLSearchParams();
    searchParams.set('tab', tab.toString());
    navigate(
      {
        search: searchParams.toString()
      },
      {
        replace: true
      }
    );
  }, []);

  if (isAssociationDetailLoading) {
    return <div></div>;
  }

  if (!associationDetail) {
    return <div></div>;
  }

  if (!association) {
    return <div>잘못된 협회이름입니다.</div>;
  }

  return (
    <>
      <MetaHelmet
        title={associationDetail?.name}
        url={'https://fibud.kr/team/' + association}
        description={
          associationDetail?.introduction ??
          '숫자만 세주는 트레이너 말고 진짜 전문가 ,PT는 피벗'
        }
        keywords={'Fibud 트레이너, ' + associationDetail?.name + ', PT'}
        img={associationDetail?.images[0].uploadedLink ?? './ms-icon-70x70.png'}
      />
      <PrevHeaderBarWithTitleTemplate title={associationDetail?.name ?? '협회'}>
        {associationDetail?.images && (
          <ProfileViewer
            imgSrcList={associationDetail?.images.map(
              image => image.uploadedLink
            )}
          ></ProfileViewer>
        )}
        <TabListViewer
          initTab={tab ? Number(tab) : 0}
          onTabChange={onTabChange}
        >
          <TabListViewer.List tabs={['소개', '소속 전문가']} />
          <TabListViewer.Viewer
            tabs={[
              <TeamIntroduce associationDetail={associationDetail} />,
              <TeamCoachesList />
            ]}
          />
        </TabListViewer>
      </PrevHeaderBarWithTitleTemplate>
    </>
  );
};

interface TeamIntroduceProps {
  associationDetail: Association;
}

function TeamIntroduce({ associationDetail }: TeamIntroduceProps) {
  const introduceContents = [
    {
      title: '소개',
      body: associationDetail.introduction
    },
    {
      title: '연혁',
      body: associationDetail.history ?? ''
    }
  ].filter(content => content.body);

  return (
    <TabIntroduce
      admin={{
        name: associationDetail.adminName,
        accountId: associationDetail.accountId
      }}
      introduceContents={introduceContents}
    />
  );
}

function TeamCoachesList() {
  const COACH_LIST_SCROLL_TOP_KEY = 'coach-list-scroll-top';
  const { setScrollRestoration } = useScrollRestoration(
    COACH_LIST_SCROLL_TOP_KEY
  );

  // 위치 정보 가져오기
  const { position } = useGeolocation();

  const { association } = useParams();

  const {
    data: coachesList,
    isLoading: isCoachesListLoading,
    hasNextPage,
    fetchNextPage
  } = useGetAssociationCoachesWithUrlQuery({
    associationUrl: association ?? '',
    lat: position.lat,
    lng: position.lng,
    offset: 10
  });

  const { setTarget } = useIntersectionObserver({
    hasNextPage,
    fetchNextPage
  });

  if (!coachesList || isCoachesListLoading) {
    return (
      <div
        style={{
          width: '100%',
          paddingTop: '30px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <div style={{ padding: '28px 20px 0' }}>
        <LocationItem />
      </div>
      <CoacheList
        coachesList={coachesList}
        setScrollRestoration={setScrollRestoration}
        setTarget={setTarget}
      />
    </div>
  );
}
