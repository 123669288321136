import { useEffect, useRef, useState } from 'react';
import { useGetTags } from '#api/tags';
import { ITag } from '#types/tags';

interface ICategory extends ITag {
  index: number;
}

/**
 * @description
 * 코치 선택 화면 상단에 있는 category selector가 사용하는 리스트를 생성하기 위한 커스텀훅입니다.
 * 리스트에 들어있는 객체에는 API를 통해 불러온 tag정보와, 인덱스 정보가 포함되어있으며,
 * 리스트의 한 가운데에는 "전체"라는 객체도 포함되어 있습니다.
 */

export const useGetCaterotyList = () => {
  const { data, isSuccess } = useGetTags();
  const listRef = useRef<ICategory[]>([]);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [isListMade, setIsListMade] = useState(false);
  const [categoryCount, setCategoryCount] = useState(0);

  const findIndex = (id: string) => {
    const index = categoryList.findIndex(category => category.id === id);
    return index === -1 ? index : index - 2;
  };

  useEffect(() => {
    if (!isSuccess) return;
    const tempCategoryList = [...data.data];

    tempCategoryList.splice(Math.floor(data.data.length / 2), 0, {
      id: 'all',
      name: '전체',
      iconName: 'BiBorderAll'
      // TODO: iconImage 추가
    });

    listRef.current = [
      ...tempCategoryList.map((tag, index) => ({
        id: tag.id,
        name: tag.name,
        iconName: tag.iconName,
        index
      }))
    ];

    listRef.current.splice(
      0,
      0,
      { id: 'firstBlank', name: '', index: -1 },
      { id: 'secondBlank', name: '', index: -1 }
    );

    listRef.current.splice(
      listRef.current.length,
      0,
      {
        id: 'lastToSecondBlank',
        name: '',
        index: -1
      },
      {
        id: 'last',
        name: '',
        index: -1
      }
    );
    setCategoryList(listRef.current);
    setCategoryCount(listRef.current.length - 4);
    setIsListMade(true);
  }, [data, isSuccess]);

  return { categoryList, isListMade, categoryCount, findIndex };
};

export default useGetCaterotyList;
