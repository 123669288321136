import React from 'react';
import { useNavigate } from 'react-router';
import { useGetAccountMyPage } from '#api/accounts';
import { useGetCoachRegisteredLectures } from '#api/registered-lectures';
import { NextArrow } from '#assets/svg';
import { Text } from '#atoms';
import { SEX } from '#const/confirmationForms/enum';
import { PATH } from '#const/path';
import {
  ageFormatter,
  dDayFormatter,
  lectureDateTimeFormatter
} from '#utils/dateFormatter';
import { TrainerBannerText } from './const';
import {
  SlideSelectorArea,
  GradientBox,
  TextArea,
  NewRequestArea,
  InClassConatiner,
  InClassConsumerContainer,
  InClassItem,
  ClassInfo,
  ClassInfoContainer
} from './styles';

export const MainTrainerTemplate = () => {
  const navigate = useNavigate();
  const { data: userInfo } = useGetAccountMyPage();
  const type = userInfo?.lecturesCount ? 'settlement' : 'profile';
  const { data: lectures } = useGetCoachRegisteredLectures({ offset: 2 });

  return (
    <>
      <SlideSelectorArea>
        <GradientBox
          onClick={() =>
            navigate(type === 'profile' ? PATH.PROFILE_UPDATE : PATH.BILLING)
          }
        >
          <TextArea>
            <Text>{TrainerBannerText[type].top_text}</Text>
            <Text>{TrainerBannerText[type].bottom_text}</Text>
          </TextArea>
          <NewRequestArea>
            <Text textStyle="t1">{TrainerBannerText[type].title}</Text>
          </NewRequestArea>
        </GradientBox>
        <InClassConatiner onClick={() => navigate(PATH.DASHBOARD)}>
          <Text textStyle="t1">수강 중인 고객</Text>
          <NextArrow />
        </InClassConatiner>
        <InClassConsumerContainer>
          {lectures?.data.length ? (
            lectures.data.map(lecture => (
              <InClassItem key={lecture.id}>
                <Text textColor="point4">{lecture.title}</Text>
                <hr />
                <ClassInfoContainer>
                  <ClassInfo>
                    <Text textStyle="c1">
                      {lecture.personName} ({SEX[lecture.personSex]},{' '}
                      {ageFormatter(lecture.personAge)})
                    </Text>
                    <Text textStyle="c1">{lecture.round}회차</Text>
                  </ClassInfo>
                  <ClassInfo>
                    <Text textStyle="c2" textColor="gray">
                      {lecture.gym.address.split(' ').slice(0, 2).join(' ')}{' '}
                    </Text>
                    <Text
                      textStyle="c2"
                      textColor={
                        dDayFormatter(lecture.taughtAt) === 'D-DAY'
                          ? 'point4'
                          : undefined
                      }
                      style={{
                        fontWeight:
                          dDayFormatter(lecture.taughtAt) === 'D-DAY'
                            ? 'bold'
                            : 'normal'
                      }}
                    >
                      {dDayFormatter(lecture.taughtAt)}
                    </Text>
                  </ClassInfo>
                  <ClassInfo className="color-gray-text time">
                    <Text textStyle="c2">{lecture.gym.name}</Text>
                    <Text textStyle="c2">
                      {lectureDateTimeFormatter(lecture.taughtAt)}
                    </Text>
                  </ClassInfo>
                </ClassInfoContainer>
              </InClassItem>
            ))
          ) : (
            <Text textColor="gray2">예정된 수업이 없습니다.</Text>
          )}
          {/* <InClassItem>
            <Text textColor="point4">수업 이름</Text>
            <hr />
            <ClassInfoContainer>
              <ClassInfo>
                <Text textStyle="c1">이름 (여, 29)</Text>
                <Text textStyle="c1">3회차</Text>
              </ClassInfo>
              <ClassInfo>
                <Text textStyle="c2" textColor="gray">
                  분당구 판교동
                </Text>
                <Text textStyle="c2">D-DAY</Text>
              </ClassInfo>
              <ClassInfo className="color-gray-text">
                <Text textStyle="c2">한솔 피트니스 센터</Text>
                <Text textStyle="c2">22.11.06 13:00 - 15:00</Text>
              </ClassInfo>
            </ClassInfoContainer>
          </InClassItem> */}
        </InClassConsumerContainer>
      </SlideSelectorArea>
    </>
  );
};
