import { useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactIcon, Text } from '#atoms';
import { $selectedCoachTagState } from '#states/atoms/tagState';
import { useGetCaterotyList } from './hooks';
import { CategorySelectorWrap, CategoryItem, DimBox } from './styles';

// swiper 추가
import 'swiper/css';

export const CategorySelector = () => {
  const listRef = useRef<HTMLUListElement>(null);
  const { categoryList, isListMade } = useGetCaterotyList();
  const [selectedCoachTag, setSelectedCoachTag] = useRecoilState(
    $selectedCoachTagState
  );
  const categoryIdx =
    categoryList?.findIndex(item => item.id === selectedCoachTag.id) !== -1
      ? categoryList.findIndex(item => item.id === selectedCoachTag.id)
      : 0;
  const [currentIndex, setCurrentIndex] = useState<number>(categoryIdx);

  const handleCategoryIndex = (index: number, type: string) => {
    const CATEGORY_INDEX = type === 'scroll' ? index - 2 : index;

    if (CATEGORY_INDEX === -1) return;

    setCurrentIndex(CATEGORY_INDEX);

    const tag = categoryList.find(
      category => category.index === CATEGORY_INDEX
    );

    if (!tag) return;
    setSelectedCoachTag({ id: tag.id, name: tag.name });
  };

  // 카테고리 패치 전 예외처리문 추가
  if (categoryList.length === 0) return null;

  return (
    <>
      <CategorySelectorWrap ref={listRef}>
        <DimBox />
        <Swiper
          slidesPerView={3}
          initialSlide={categoryIdx}
          centeredSlides={true}
          centeredSlidesBounds={true}
          slideToClickedSlide={true}
          onSlideChange={el => handleCategoryIndex(el.activeIndex, 'scroll')}
          breakpoints={{
            // 400이상에서는 5개이다.
            400: {
              slidesPerView: 5
            }
          }}
        >
          {isListMade &&
            categoryList.map(item => (
              <SwiperSlide
                key={item.id}
                onClick={() => handleCategoryIndex(item.index, 'click')}
              >
                <CategoryItem key={item.id}>
                  {item.name && (
                    <>
                      <div>
                        <ReactIcon iconName={item.iconName ?? ''} />
                      </div>
                      <Text
                        textStyle="c1"
                        textColor={
                          item.index !== currentIndex ? 'gray2' : undefined
                        }
                      >
                        {/\s/.test(item.name)
                          ? item.name.split(' ').map((word, index) => (
                              <Text key={index} style={{ textAlign: 'center' }}>
                                {word}
                                <br />
                              </Text>
                            ))
                          : item.name}
                      </Text>
                    </>
                  )}
                </CategoryItem>
              </SwiperSlide>
            ))}
        </Swiper>
      </CategorySelectorWrap>
    </>
  );
};
