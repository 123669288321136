import { throttle } from 'lodash';
import { useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Button, Input, Line, Text, VerticalLine } from '#atoms';
import { Modal } from '#molecules';
import { useScheduleManage } from '#providers/SchedulesManage';
import { getWeeksInMonth } from '#utils/dateFormatter';
import useWeeklyCalender from 'src/components/pages/WeeklyCalender/hook/useWeeklyCalender';
import {
  Container,
  DateContainer,
  ModalTitle,
  MonthBox,
  WeekBox,
  YearBox,
  CurrentBox,
  ButtonBox,
  DateBox
} from './styles';

interface Props {
  onClose: () => void;
}

interface SwipeHandlers {
  year: ReturnType<typeof useSwipeable>;
  month: ReturnType<typeof useSwipeable>;
  week: ReturnType<typeof useSwipeable>;
}
export function DaySelectModal({ onClose }: Props) {
  const { currentDate, setCurrentDate } = useScheduleManage();

  const { year, month, week } = currentDate;

  const changeDate = (type: string, delta: number) => {
    switch (type) {
      case 'year':
        const newYear = currentDate.year + delta;
        if (newYear >= 1 && newYear <= 3000) {
          setCurrentDate({ ...currentDate, year: newYear });
        }
        break;
      case 'month':
        const newMonth = ((currentDate.month + delta - 1) % 12) + 1;
        const weeksInNewMonth = getWeeksInMonth(currentDate.year, newMonth);
        setCurrentDate({
          ...currentDate,
          month: newMonth < 1 ? 12 : newMonth > 12 ? 1 : newMonth,
          week:
            currentDate.week > weeksInNewMonth
              ? weeksInNewMonth
              : currentDate.week
        });
        break;
      case 'week':
        const weeksInMonth = getWeeksInMonth(
          currentDate.year,
          currentDate.month
        );
        const newWeek = ((currentDate.week + delta - 1) % weeksInMonth) + 1;
        setCurrentDate({
          ...currentDate,
          week:
            newWeek < 1 ? weeksInMonth : newWeek > weeksInMonth ? 1 : newWeek
        });
        break;
    }
  };

  const handleWheel = throttle((event: React.WheelEvent<HTMLDivElement>) => {
    const type = event.currentTarget.dataset.type || '';
    const delta = event.deltaY > 0 ? 1 : -1;
    changeDate(type, delta);
  }, 500);

  const swipeHandlers: SwipeHandlers = {
    year: useSwipeable({
      onSwipedUp: () => changeDate('year', 1),
      onSwipedDown: () => changeDate('year', -1)
    }),
    month: useSwipeable({
      onSwipedUp: () => changeDate('month', 1),
      onSwipedDown: () => changeDate('month', -1)
    }),
    week: useSwipeable({
      onSwipedUp: () => changeDate('week', 1),
      onSwipedDown: () => changeDate('week', -1)
    })
  };

  const saveCurrentDate = () => {
    setCurrentDate({
      year,
      month,
      week
    });
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <Container>
        <ModalTitle>
          <Text textStyle="bb">날짜 선택</Text>
        </ModalTitle>
        <DateContainer>
          <YearBox
            {...swipeHandlers.year}
            onWheel={handleWheel}
            data-type="year"
          >
            <DateBox>
              {year > 1 ? (
                <Text>{year === 1 ? year : year - 1}</Text>
              ) : (
                <Text></Text>
              )}
              <CurrentBox>
                <Input
                  value={year}
                  type={'string'}
                  onChange={e => {
                    const value = Number(e) < 3000 ? Number(e) : 3000;
                    setCurrentDate(prev => {
                      return { ...prev, year: value };
                    });
                  }}
                />
              </CurrentBox>
              <Text>{year + 1}</Text>
            </DateBox>
            <Text type="inline">년</Text>
          </YearBox>
          <MonthBox
            {...swipeHandlers.month}
            onWheel={handleWheel}
            data-type="month"
          >
            <DateBox>
              <Text>{month === 1 ? 12 : month - 1}</Text>
              <CurrentBox>
                <Input
                  value={month}
                  type={'string'}
                  onChange={e => {
                    const value = Number(e) < 12 ? Number(e) : 12;
                    setCurrentDate(prev => {
                      return { ...prev, month: value };
                    });
                  }}
                />
              </CurrentBox>
              <Text>{month === 12 ? 1 : month + 1}</Text>
            </DateBox>
            <Text type="inline">월</Text>
          </MonthBox>
          <WeekBox
            {...swipeHandlers.week}
            onWheel={handleWheel}
            data-type="week"
          >
            <DateBox>
              <Text>
                {week - 1 === 0 ? getWeeksInMonth(year, month) : week - 1}
              </Text>
              <CurrentBox>
                <Input
                  value={week}
                  type={'string'}
                  onChange={e => {
                    const value = Number(e) < 12 ? Number(e) : 12;
                    setCurrentDate(prev => {
                      return { ...prev, week: value };
                    });
                  }}
                />
              </CurrentBox>
              <Text>
                {week === getWeeksInMonth(year, month) ? 1 : week + 1}
              </Text>
            </DateBox>
            <Text type="inline">째주</Text>
          </WeekBox>
        </DateContainer>
      </Container>
      <ButtonBox>
        <Button background="anti" onClick={onClose}>
          취소
        </Button>
        <Button onClick={saveCurrentDate}>확인</Button>
      </ButtonBox>
    </Modal>
  );
}
