import Logo from '#assets/img/LOGO (1).svg';
import { PATH } from '#const/path';
import { TabListViewer } from '#molecules';
import {
  CustomerListPage,
  MainHomePage,
  MainIntroductionPage,
  WeeklyCalender
} from '#pages';
import UserInfo from '#providers/UserInfo';
import { HeaderBarTemplate } from '#templates';
import sessionStorageManager from '#utils/sessionStorageManager';
import TokenController from '#utils/tokenController';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { MainConsumerView } from './MainConsumerView';
import { Container, MyInfoNavigator, TitleRightContainer } from './styles';

const CoachTabList = {
  listTabs: ['소개', '홈', '일정 관리', '고객관리'],
  viewerTabs: [
    <MainIntroductionPage />,
    <MainHomePage />,
    <WeeklyCalender />,
    <CustomerListPage />
  ]
};

export function MainView() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = Number(searchParams.get('tab') ?? 1);
  const coachName = searchParams.get('coach-name');
  const { alarmLoginRedirectPath, removeSessionData } = sessionStorageManager();

  const handleTabClick = (tab: number) => {
    setSearchParams({ tab: tab.toString() });
  };

  if (alarmLoginRedirectPath) {
    window.location.href = window.location.origin + alarmLoginRedirectPath;
    removeSessionData('alarmLoginRedirectPath');
  }

  useEffect(() => {
    if (!TokenController.accessToken && coachName) {
      navigate(PATH.SIGN_ALARM + '?coach-name=' + coachName);
    }
  }, []);

  if (!TokenController.accessToken && coachName) {
    return <></>;
  }

  return (
    <Container>
      <HeaderBarTemplate
        // TitleLeft={<BoxIcon type="QR" size={30} onlyIcon />}
        TitleCenter={<img src={Logo} />}
        TitleRight={
          <TitleRightContainer>
            {/* <BoxIcon type="notification" size={23} onlyIcon={true} /> */}
            <MyInfoNavigator onClick={() => navigate(PATH.MYPAGE)} />
          </TitleRightContainer>
        }
      >
        <TabListViewer initTab={tab}>
          <UserInfo.ConsumerLike>
            <MainConsumerView />
          </UserInfo.ConsumerLike>
          <UserInfo.IsCoach>
            <TabListViewer.List
              tabs={CoachTabList.listTabs}
              onClick={handleTabClick}
            />
            <TabListViewer.Viewer tabs={CoachTabList.viewerTabs} />
          </UserInfo.IsCoach>
        </TabListViewer>
      </HeaderBarTemplate>
    </Container>
  );
}
