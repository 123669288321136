import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 5rem;
  overflow-y: auto;
  height: calc(100 * var(--vh) - 58px);

  &.bottom {
    padding-bottom: 17rem;
  }
`;

export const ButtonArea = styled.div`
  width: 100%;
`;

export const ClinicalDataContainer = styled.div`
  padding: 2rem;
  display: inline-block;
  width: 100%;
  border-top: 1rem solid var(--color-gray-third);
  border-bottom: 1rem solid var(--color-gray-third);

  > div {
    overflow-x: auto;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
`;
