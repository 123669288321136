import React from 'react';
import { useParams } from 'react-router';
import { useGetClinicalDataList } from '#api/clinical-data';
import { useGetCoachIdFromMyUrl } from '#api/my-url';
import { Text } from '#atoms';
import { ClinicalDataItem } from '#organisims';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { Container } from './styles';

export function CoachClinicalDataPage() {
  const { myUrl } = useParams();
  const { data: coachId } = useGetCoachIdFromMyUrl(myUrl ?? '');

  const { data: clinicalDataList } = useGetClinicalDataList({
    coachId: coachId ?? '',
    offset: 100
  });

  return (
    <PrevHeaderBarWithTitleTemplate title="포트폴리오" defaultBackground>
      <Container>
        <Text textStyle="bb">총 {clinicalDataList?.total}개의 포트폴리오</Text>
        {clinicalDataList &&
          clinicalDataList.data.map((data, idx) => (
            <ClinicalDataItem data={data} key={idx} />
          ))}
      </Container>
    </PrevHeaderBarWithTitleTemplate>
  );
}
