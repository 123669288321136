import { useGetLecturesIngList } from '#api/lectures';
import { Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { useAttendingLectures } from '#providers/AttendLecture';
import {
  FibudContainerTemplate,
  FindCoachTemplate,
  PrevHeaderBarWithTitleTemplate,
  Progress
} from '#templates';
import sessionStorageManager from '#utils/sessionStorageManager';
import { useLocation, useNavigate } from 'react-router';
import { LoadingContainer, ScheduleList, TextContainer } from './styles';

export function MyPageScheduleHome() {
  // 진입했을 때, data 호출부가 필요함
  const { data, isLoading } = useGetLecturesIngList();
  const { updateSelectedLecture } = useAttendingLectures();
  const { setConfirmationFormId } = sessionStorageManager();
  const navigate = useNavigate();
  const location = useLocation();

  if (isLoading) {
    return <LoadingContainer>loading...</LoadingContainer>;
  }

  return (
    <Progress>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={location.state?.prevLocation ?? PATH.MYPAGE}
        title="수업 일정 관리"
        defaultBackground
      >
        {/* <AttendingLecture.EmptyLecture> */}
        {data && data.length ? (
          <>
            <FibudContainerTemplate type="common">
              {/* <AttendingLecture.HasLecture> */}
              <TextContainer>
                <Text textStyle="t1">진행중인 수업</Text>
              </TextContainer>
              <ScheduleList>
                {data?.map((ingLecture, idx) => (
                  <li key={idx}>
                    <div>
                      <Text textStyle="t3">{ingLecture.lecture.title}</Text>
                      <Text textStyle="t3">
                        {ingLecture.lecture.coachName} 전문가
                      </Text>
                    </div>
                    <div>
                      <Text textStyle="c1" textColor="gray2">
                        {ingLecture.gymName}
                      </Text>
                      <Text textStyle="c1" textColor="gray2">
                        {`${ingLecture.calculatedRegisteredLecture.registeredLectureCount} / ${ingLecture.calculatedRegisteredLecture.totalRegisteredLectureCount} 회`}
                      </Text>
                    </div>
                    {ingLecture.status === 'TAKEN' ? (
                      <Button
                        onClick={() => {
                          {
                            setConfirmationFormId(
                              ingLecture.confirmationFormId || ''
                            );
                            updateSelectedLecture(ingLecture);
                            navigate(
                              `${PATH.APPLICATION}??confirmation-form-id=${ingLecture.confirmationFormId}`,
                              {
                                state: {
                                  page: 2
                                }
                              }
                            );
                          }
                        }}
                      >
                        수강 신청서 작성하기
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          updateSelectedLecture(ingLecture);
                          navigate(
                            `${PATH.MYPAGE_SCHEDULES}/${ingLecture.lecture.id}`
                          );
                        }}
                      >
                        일정 보기
                      </Button>
                    )}
                  </li>
                ))}
              </ScheduleList>
            </FibudContainerTemplate>
          </>
        ) : (
          <FindCoachTemplate />
        )}
      </PrevHeaderBarWithTitleTemplate>
    </Progress>
  );
}
