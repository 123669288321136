// import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';
// import { useNavigate } from 'react-router';
// import { Text } from '#atoms';
// import { PATH } from '#const/path';
// import { useScheduleManage } from '#providers/SchedulesManage';
// import { ReservedMembersType } from '#types/schedule';
// import { HourBox, Time, TimeBox, TimeLineContainer, BookedBox } from './styles';
// import { getRandomColorWithModifiedSaturation } from '#utils/colorPicker';
// dayjs.extend(utc);

// export const time = [
//   '06',
//   '06:30',
//   '07',
//   '07:30',
//   '08',
//   '08:30',
//   '09',
//   '09:30',
//   '10',
//   '10:30',
//   '11',
//   '11:30',
//   '12',
//   '12:30',
//   '13',
//   '13:30',
//   '14',
//   '14:30',
//   '15',
//   '15:30',
//   '16',
//   '16:30',
//   '17',
//   '17:30',
//   '18',
//   '18:30',
//   '19',
//   '19:30',
//   '20',
//   '20:30',
//   '21',
//   '21:30',
//   '22',
//   '22:30',
//   '23',
//   '23:30',
//   '24'
// ];

export function TimeLine() {
  // const {
  //   currentDate,
  //   formatWeekData,
  //   startDateWeek,
  //   weekDayAndDates,
  //   setSelectedLecture
  // } = useScheduleManage();
  // const navigate = useNavigate();

  // const isBooked = (date: string, hour: string) => {
  //   const bookedDate = formatWeekData.find(booked => booked.date === date);

  //   if (bookedDate) {
  //     const bookedMember = bookedDate.reservedMembers.find(member => {
  //       const startTime = dayjs.utc(member.taughtAt).local();
  //       const endTime = dayjs.utc(member.endedAt).local();
  //       const currentTime = dayjs.utc(`${date} ${hour}`, 'YYYY-MM-DD HH:mm');
  //       return (
  //         (currentTime.isSame(startTime) || currentTime.isAfter(startTime)) &&
  //         currentTime.isBefore(endTime)
  //       );
  //     });
  //     return bookedMember;
  //   }

  //   return undefined;
  // };

  // const moveToModifyPage = (bookedMember: ReservedMembersType) => {
  //   setSelectedLecture(bookedMember);
  //   navigate(`${PATH.CALENDER_DETAIL}/${bookedMember.lectureId}`);
  // };

  return <></>;
  // <div>
  //   <TimeLineContainer>
  //     <HourBox>
  //       {time.map((hour, index) => {
  //         if (!hour.includes(':30')) {
  //           return (
  //             <div key={index}>
  //               <Text>
  //                 {hour}
  //                 <br />
  //                 {index < 12 ? 'AM' : 'PM'}
  //               </Text>
  //             </div>
  //           );
  //         } else {
  //           return <div key={index}></div>;
  //         }
  //       })}
  //     </HourBox>
  //     <TimeBox>
  //       {time.map((hour, index) => {
  //         return (
  //           <Time key={index}>
  //             {weekDayAndDates.map(day => {
  //               const bookedMember = isBooked(day.date, hour);
  //               return (
  //                 <BookedBox
  //                   key={`${day.date}-${hour}`}
  //                   style={{
  //                     backgroundColor: bookedMember
  //                       ? getRandomColorWithModifiedSaturation(
  //                           bookedMember.lectureId
  //                         )
  //                       : 'transparent'
  //                   }}
  //                   onClick={() =>
  //                     bookedMember
  //                       ? moveToModifyPage(bookedMember)
  //                       : navigate(
  //                           PATH.Weekly_Calender_Schedule_Registration,
  //                           {
  //                             state: {
  //                               date: day.date,
  //                               startTime: hour.includes(':')
  //                                 ? hour
  //                                 : `${hour}:00`
  //                             }
  //                           }
  //                         )
  //                   }
  //                   isStart={
  //                     bookedMember &&
  //                     dayjs
  //                       .utc(`${day.date} ${hour}`, 'YYYY-MM-DD HH:mm')
  //                       .local()
  //                       .isSame(dayjs.utc(bookedMember.taughtAt).local())
  //                       ? true
  //                       : false
  //                   }
  //                   isEnd={
  //                     bookedMember &&
  //                     dayjs
  //                       .utc(`${day.date} ${hour}`, 'YYYY-MM-DD HH:mm')
  //                       .local()
  //                       .isAfter(dayjs.utc(bookedMember.taughtAt).local()) &&
  //                     dayjs
  //                       .utc(`${day.date} ${hour}`, 'YYYY-MM-DD HH:mm')
  //                       .isBefore(dayjs.utc(bookedMember.endedAt))
  //                       ? true
  //                       : false
  //                   }
  //                 >
  //                   {bookedMember &&
  //                   dayjs
  //                     .utc(`${day.date} ${hour}`, 'YYYY-MM-DD HH:mm')
  //                     .local()
  //                     .isSame(dayjs.utc(bookedMember.taughtAt).local())
  //                     ? bookedMember.personName
  //                     : ''}
  //                 </BookedBox>
  //               );
  //             })}
  //           </Time>
  //         );
  //       })}
  //     </TimeBox>
  //   </TimeLineContainer>
  // </div>
  // );
}
