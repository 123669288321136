import { useGetAccount } from '#api/accounts';
import { useGetCoachLectures } from '#api/lectures';
import { useGetCoachIdFromMyUrl } from '#api/my-url';
import { useRouteHistory } from '#hooks/useRouteHistory';
import { $coachLectureState } from '#states/atoms/coachLectureState';
import { $modalFamily, MODAL_TYPE } from '#states/atoms/modalState';
import {
  CoachDetailApplyModal,
  CoachDetailContent,
  CoachDetailLoginModal,
  FibudContainerTemplate,
  PrevHeaderBarWithTitleTemplate
} from '#templates';
import { Lecture } from '#types/lectures';
import MetaHelmet from '#utils/MetaHelmet';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

export const CoachDetailPage = () => {
  const { myUrl } = useParams();
  if (!myUrl) return <></>;
  const { data: coachId } = useGetCoachIdFromMyUrl(myUrl);
  const { clearRouteHistory } = useRouteHistory();

  const { data: coachData, isError: getAccountError } = useGetAccount(
    coachId ?? ''
  );
  const { data: lecturesData, isError: getLecturesError } = useGetCoachLectures(
    coachId ?? ''
  );

  const setCoachLectureState = useSetRecoilState($coachLectureState);
  const [showApplyModal, setShowApplyModal] = useRecoilState(
    $modalFamily(MODAL_TYPE.APPLY)
  );
  const [showLoginModal, setShowLoginModal] = useRecoilState(
    $modalFamily(MODAL_TYPE.LOGIN)
  );
  const resetLoginModalState = useResetRecoilState(
    $modalFamily(MODAL_TYPE.LOGIN)
  );
  const resetApplyModalState = useResetRecoilState(
    $modalFamily(MODAL_TYPE.APPLY)
  );
  const [showCoachNameInHeader, setShowCoachNameInHeader] = useState(false);
  const [applyTagEvent, setApplyTagEvent] = useState<boolean>(false);

  useEffect(() => {
    if (!lecturesData || !coachData) return;

    const data = lecturesData.data.map((lecture: Lecture) => ({
      id: lecture.id,
      coachId: lecture.coachId,
      gymId: coachData.recommendGyms[0]?.id,
      price: lecture.price,
      recommendRoundPerWeek: lecture.recommendRoundPerWeek ?? 0,
      title: lecture.title,
      body: lecture.body,
      rounds: lecture.rounds,
      coachName: lecture.coachName,
      gymName: coachData.recommendGyms[0]?.name,
      isOt: lecture.isOt,
      isOnePoint: lecture.isOnePoint
    }));

    setCoachLectureState(data);

    return () => {
      resetApplyModalState();
      resetLoginModalState();
    };
  }, [lecturesData, coachData]);

  return (
    <>
      <MetaHelmet
        title={`${coachData?.name ?? ''} ${coachData?.tags
          .map(tag => tag.name)
          .join(' | ')} 전문가 - ${coachData?.recommendGyms[0]?.name}`}
        keywords={` ${coachData?.name} 전문가, ${coachData?.recommendGyms[0]?.name}, ${coachData?.recommendGyms[0]?.address}`}
        description={
          `${coachData?.introduction}` ??
          '숫자만 세주는 트레이너 말고 진짜 전문가 ,PT는 피벗'
        }
        img={`${coachData?.profile[0]?.resizedLink}` ?? './ms-icon-70x70.png'}
        url={'https://fibud.kr/expert' + myUrl}
      />

      <PrevHeaderBarWithTitleTemplate
        hide={coachData?.status !== 'ACCEPT'}
        title={showCoachNameInHeader ? `${coachData?.name} 전문가` : ''}
        defaultBackground
        onPrevCallback={clearRouteHistory}
      >
        {(getAccountError || getLecturesError) && (
          <div style={{ color: 'white' }}>잘못된 접근입니다!</div>
        )}
        <FibudContainerTemplate>
          {coachData && coachData.id === coachId && (
            <CoachDetailContent
              coachData={coachData}
              lectureData={lecturesData?.data ?? []}
              setShowCoachNameInHeader={setShowCoachNameInHeader}
              applyTagEvent={applyTagEvent}
              setApplyTagEvent={setApplyTagEvent}
            />
          )}
        </FibudContainerTemplate>
        {showApplyModal && (
          <CoachDetailApplyModal
            setShowModal={setShowApplyModal}
            onApplyButtonClick={() => setApplyTagEvent(true)}
          />
        )}
        {showLoginModal && (
          <CoachDetailLoginModal setShowModal={setShowLoginModal} />
        )}
      </PrevHeaderBarWithTitleTemplate>
    </>
  );
};
