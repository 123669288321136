const storageKeys = {
  districtCode: 'districtCode',
  districtSigu: 'districtSigu',
  currentLocation: 'currentLocation',
  currentAddress: 'currentAddress',
  blockedPagePath: 'blockedPagePath',
  confirmationFormId: 'confirmationFormId',
  alarmLoginRedirectPath: 'alarmLoginRedirectPath'
} as const;

interface LocationData {
  latitude?: number;
  longitude?: number;
  address: string;
}

const sessionStorageManager = () => {
  const currentSession = {
    districtCode: sessionStorage.getItem(storageKeys.districtCode),
    districtSigu: sessionStorage.getItem(storageKeys.districtSigu),
    currentLocation: sessionStorage.getItem(storageKeys.currentLocation),
    currentAddress: sessionStorage.getItem(storageKeys.currentAddress),
    blockedPagePath: sessionStorage.getItem(storageKeys.blockedPagePath),
    confirmationFormId: sessionStorage.getItem(storageKeys.confirmationFormId),
    alarmLoginRedirectPath: sessionStorage.getItem(
      storageKeys.alarmLoginRedirectPath
    )
  };

  const districtCode = currentSession.districtCode;
  const districtSigu = currentSession.districtSigu;
  const currentLocation = currentSession.currentLocation;
  const currentAddress = currentSession.currentAddress;
  const blockedPagePath = currentSession.blockedPagePath;
  const confirmationFormId = currentSession.confirmationFormId;
  const alarmLoginRedirectPath = currentSession.alarmLoginRedirectPath;

  const setDistrictCode = (districtCode: string) => {
    sessionStorage.setItem(storageKeys.districtCode, districtCode);
  };

  const setDistrictSigu = (districtSigu: string) => {
    sessionStorage.setItem(storageKeys.districtSigu, districtSigu);
  };

  const setCurrentLocation = (location: LocationData) => {
    const { latitude, longitude, address } = location;
    const coordinates = `${latitude},${longitude}`;
    sessionStorage.setItem(storageKeys.currentLocation, coordinates);
    sessionStorage.setItem(storageKeys.currentAddress, address);
  };

  const setBlockedPagePath = (blockedPagePath: string) => {
    sessionStorage.setItem(storageKeys.blockedPagePath, blockedPagePath);
  };

  const setConfirmationFormId = (confirmationFormId: string) => {
    sessionStorage.setItem(storageKeys.confirmationFormId, confirmationFormId);
  };

  const setAlarmTalkRedirectPath = (alarmLoginRedirectPath: string) => {
    sessionStorage.setItem(
      storageKeys.alarmLoginRedirectPath,
      alarmLoginRedirectPath
    );
  };

  /** 세션스토리지 키값으로 스토리지 삭제 */
  const removeSessionData = (key: keyof typeof storageKeys) => {
    sessionStorage.removeItem(storageKeys[key]);
  };

  return {
    districtCode,
    districtSigu,
    currentLocation,
    currentAddress,
    blockedPagePath,
    confirmationFormId,
    alarmLoginRedirectPath,
    setDistrictCode,
    setDistrictSigu,
    setCurrentLocation,
    setBlockedPagePath,
    setConfirmationFormId,
    setAlarmTalkRedirectPath,
    removeSessionData
  };
};

export default sessionStorageManager;
