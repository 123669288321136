import { useEffect } from 'react';
import { useLocation } from 'react-router';

export default function useScrollRestoration(key: string, timer = 300) {
  const location = useLocation();
  const storage = window.sessionStorage;

  const setScrollRestoration = () => {
    storage.setItem(key, String(window.scrollY));
  };

  useEffect(() => {
    if (storage.getItem(key)) {
      const scrollY = Number(storage.getItem(key));
      storage.removeItem(key);
      setTimeout(() => window.scrollTo(0, scrollY), timer);
    }
  }, [location]);

  return { setScrollRestoration };
}
