import React from 'react';
import { useNavigate } from 'react-router';
import { Text } from '#atoms';
import { PATH } from '#const/path';
import { CoachCustomerList } from '#organisims';
import CustomerList from '#providers/CustomerList';
import { useDashboard } from '#providers/Dashboard';
import { IGetCustomerDetailProps } from '#types/confirmationForms';
import MetaHelmet from '#utils/MetaHelmet';
import { TextContainer } from '../Dashboard/styles';

export function CustomerListPage() {
  const { setConfirmationFormIdProps } = useDashboard();
  const navigate = useNavigate();

  const handleDetailClick = ({ personId, id }: IGetCustomerDetailProps) => {
    setConfirmationFormIdProps({ personId, id });
    navigate(PATH.DASHBOARD_CUSTOMER);
  };

  return (
    <>
      <MetaHelmet title="피벗 운동차트" />
      <CustomerList.IsLoading></CustomerList.IsLoading>
      <CustomerList.IsError>잘못된 접근입니다!</CustomerList.IsError>
      <CustomerList.EmptyList>
        <TextContainer>
          <Text textStyle="t3">
            아직 고객 정보가 없어요!
            <br />
            수업을 홍보하고 이용해보세요
          </Text>
        </TextContainer>
      </CustomerList.EmptyList>
      <CustomerList.HasList>
        <CoachCustomerList onDetailClick={handleDetailClick} />
      </CustomerList.HasList>
    </>
  );
}
