import { atom } from 'recoil';

interface ISignup {
  name: string | null;
  nickname: string | null;
  phone: string | null;
  email: string | null;
  password: string | null;
  agreeNotification: boolean;
  sex: 'MALE' | 'FEMALE' | null;
  birthday: string | null;
  isCoach: boolean | null;
  isAdmin: boolean | null;
  selectedPolicies?: string[];
  authNumber?: string | null;
}

const ATOM_KEY = 'signupState';

export const $signupState = atom<ISignup>({
  key: ATOM_KEY,
  default: {
    name: null,
    nickname: null,
    phone: null,
    email: null,
    password: null,
    agreeNotification: false,
    sex: null,
    birthday: null,
    isCoach: null,
    isAdmin: null,
    selectedPolicies: [],
    authNumber: null
  }
});
