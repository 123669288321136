import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useGetTags } from '#api/tags';
import { ReactIcon, Text } from '#atoms';
import { $selectedCoachTagState } from '#states/atoms/tagState';
import { CategoryArea, CategoryItem, Container } from './styles';

export const MainCategorySelector = () => {
  const [isExpand, setIsExpand] = useState(false);
  const { data, isSuccess } = useGetTags();
  const setSelectedCoachTag = useSetRecoilState($selectedCoachTagState);
  const [, setSearchParams] = useSearchParams();

  const handleToggleButtonClick = () => {
    setIsExpand(prev => !prev);
  };

  const handleCategoryButtonclick = (id: string, name: string) => {
    setSelectedCoachTag({ id, name });
    setSearchParams({ tab: '2' });
  };

  return (
    <Container>
      <Text textColor="gray2" textStyle="bb">
        분야별 전문가 찾기
      </Text>
      <CategoryArea>
        {isSuccess &&
          data.data.map(item => (
            <CategoryItem
              key={item.id}
              onClick={() => handleCategoryButtonclick(item.id, item.name)}
            >
              <ReactIcon iconName={item.iconName ?? ''} />
              <Text textStyle="c2" className="bold">
                {/\s/.test(item.name)
                  ? item.name.split(' ').map((word, index) => (
                      <Text key={index} style={{ textAlign: 'center' }}>
                        {word}
                        <br />
                      </Text>
                    ))
                  : item.name}
              </Text>
            </CategoryItem>
          ))}
      </CategoryArea>
      {/* <ExpandHandle
        className={classNames({ fold: !isExpand })}
        onClick={handleToggleButtonClick}
      >
        <NextArrow stroke={colors.grayText2} />
      </ExpandHandle> */}
    </Container>
  );
};
