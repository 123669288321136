import { useGetRecentClinicalData } from '#api/clinical-data';
import { useGetRecentReviews } from '#api/reviews';
import { MainCategorySelector, MainHomeBanner } from '#organisims';
import { useAttendingLectures } from '#providers/AttendLecture';
import { useUserInfo } from '#providers/UserInfo';
import { ClinicalDataList } from 'src/components/organisims/MainHomeBanner/ClinicalDataList';
import { ReviewList } from 'src/components/organisims/MainHomeBanner/ReviewList';
import { SlideSelectorArea } from './styles';

export const MainConsumerTemplate = () => {
  const { userInfo } = useUserInfo();
  const { lectures, calculatedRemainingLecture, hasTakenStatusIngLecture } =
    useAttendingLectures();
  const { data: recentClinicalDataList } = useGetRecentClinicalData();
  const { data: recentReviews } = useGetRecentReviews();

  const hasActiveLectures =
    userInfo &&
    !userInfo.isCoach &&
    lectures.length !== 0 &&
    calculatedRemainingLecture !== 0 &&
    !hasTakenStatusIngLecture;

  return (
    <SlideSelectorArea>
      {/* <MainHomeBanner type="intro" /> */}
      <div style={{ minHeight: '162px' }}>
        {hasActiveLectures ? (
          <MainHomeBanner type="schedule" />
        ) : (
          <MainCategorySelector />
        )}
      </div>
      <div style={{ height: '334px' }}>
        {!!recentClinicalDataList?.length && (
          <ClinicalDataList data={recentClinicalDataList} />
        )}
      </div>
      <div style={{ height: '294px' }}>
        {!!recentReviews?.length && <ReviewList data={recentReviews} />}
      </div>
    </SlideSelectorArea>
  );
};
