import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spacing, Text } from '#atoms';
import { PATH } from '#const/path';
import {
  RecordDetailExerciseRecordList,
  RecordDetailLectureInfo
} from '#organisims';
import LectureChart, { useLectureChart } from '#providers/LectureChart';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import TokenController from '#utils/tokenController';
import { CoachCommentContainer, Container } from './styles';

export const RecordDetailPage = () => {
  const { setRegisteredLectureId, record } = useLectureChart();
  const [searchParams] = useSearchParams();
  const registeredLectureId = searchParams.get('id');
  const coachName = searchParams.get('coach-name');
  const navigate = useNavigate();

  useEffect(() => {
    registeredLectureId && setRegisteredLectureId(registeredLectureId);
  }, [registeredLectureId]);

  return (
    <Container>
      <PrevHeaderBarWithTitleTemplate
        prevRoute={`${PATH.CHART_LECTURE}?id=${record.registeredLectureRecord?.lectureId}`}
        title="운동차트"
        defaultBackground
      >
        <LectureChart.IsLoading>로딩중...</LectureChart.IsLoading>
        <LectureChart.IsError>잘못된 접근입니다!</LectureChart.IsError>
        <LectureChart.EmptyRecord>
          운동 기록이 없습니다.
        </LectureChart.EmptyRecord>
        <LectureChart.HasRecord>
          <RecordDetailLectureInfo
            {...record.registeredLectureRecord}
            {...record.lectureRate}
          />
          {record.exerciseRecord?.length ? (
            <>
              <RecordDetailExerciseRecordList
                exerciseRecord={record.exerciseRecord}
              />

              <CoachCommentContainer>
                <Text textStyle="t3">전문가 코멘트</Text>
                <Text textColor="gray2" style={{ whiteSpace: 'pre-wrap' }}>
                  {record.registeredLectureRecord?.comment || ''}
                </Text>
              </CoachCommentContainer>
              <Spacing size={50} />
            </>
          ) : (
            <Text
              textColor="gray2"
              style={{ textAlign: 'center', padding: '7rem 0' }}
            >
              운동차트가 아직 없습니다.
            </Text>
          )}
        </LectureChart.HasRecord>
      </PrevHeaderBarWithTitleTemplate>
    </Container>
  );
};
