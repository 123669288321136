import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import isoWeek from 'dayjs/plugin/isoWeek';
import { DATE } from '#const/enrollment/calendar';
import { FibudDate } from '#providers/Schedules';
dayjs.extend(isoWeek);

export const dateFormatter = (dateData: string) => {
  const dateObject = new Date(dateData);
  const month = dateObject.getMonth() + 1;
  const date = dateObject.getDate();

  return `${month}월 ${date}일`;
};

export const exerciseCareerFormatter = (startAthletic?: string) => {
  const careerYear = startAthletic
    ? dayjs().diff(dayjs(startAthletic), 'year')
    : 0;
  return careerYear === 0
    ? '경력 없음'
    : careerYear >= 5
    ? '5년 이상'
    : `${careerYear}년`;
};

export const dateDayFormatter = (date: string) => {
  dayjs.locale('ko');
  return dayjs(date).format('YYYY.MM.DD ddd');
};

export const monthDayFormatter = (dateString: string) => {
  const dateObject = dayjs(dateString);
  return dateObject.format('MM/DD');
};

export const yearMonthDayFormatter = (date?: Date | string) => {
  const dateObject = dayjs(date);
  return dateObject.format('YYYY-MM-DD');
};

export const dottedDateFormatter = (date?: string) => {
  const dateObject = dayjs(date);
  return dateObject.format('YYYY.MM.DD');
};

export const dateNameFormatter = (dayValues: string) => {
  return DATE.filter(dateItem => dayValues.includes(String(dateItem.key)))
    .sort((a, b) => DATE.indexOf(a) - DATE.indexOf(b))
    .map(dateItem => dateItem.text)
    .join(', ');
};

export const ageFormatter = (birthday: string) => {
  return `${dayjs().diff(dayjs(birthday), 'year')}세`;
};

export const dDayFormatter = (date: string) => {
  // date: YYYY-MM-DD
  const dateString = date.slice(0, 10);
  const eventDate = new Date(dateString);

  const today = new Date();
  const dayDifference =
    -1 *
    Math.ceil((eventDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

  return dayDifference === 0
    ? 'D-DAY'
    : `D${dayDifference > 0 ? '+' : ''}${dayDifference}`;
};

export const lectureDateTimeFormatter = (date: string) => {
  const lectureDate = new Date(date);

  const year = lectureDate.getFullYear();
  const month = (lectureDate.getMonth() + 1).toString().padStart(2, '0');
  const day = lectureDate.getDate().toString().padStart(2, '0');
  const hours = lectureDate.getHours().toString().padStart(2, '0');
  const minutes = lectureDate.getMinutes().toString().padStart(2, '0');

  const endHours = ((lectureDate.getHours() + 1) % 24)
    .toString()
    .padStart(2, '0');

  return `${year}.${month}.${day} ${hours}:${minutes} - ${endHours}:${minutes}`;
};

export const convertSelectedDateToScheduleDate = (date?: FibudDate) => {
  if (!date || !date.hour) return '';
  return `${date.year}년 ${date.month}월 ${date.date}일 ${dayjs()
    .hour(date.hour)
    .format('HH')}:00 - ${dayjs()
    .hour(date.hour + 1)
    .format('HH')}:00`;
};

export const convertRegisteredDateToScheduleDate = (
  registeredDate: string,
  hasDay?: boolean
) => {
  const date = dayjs(registeredDate);

  return `${date.year()}년 ${date.month() + 1}월 ${date.date()}일 ${
    hasDay ? date.locale('ko').format('dddd') + ' ' : ''
  }${date.hour()}:${date.format('mm')} - ${date.add(1, 'hour').hour()}:${date
    .add(1, 'hour')
    .format('mm')}`;
};

export const dateDayKoFormatter = (date: string) => {
  dayjs.locale('ko');
  return dayjs(date).format('YYYY년 M월 D일 dddd');
};

export function getWeeksInMonth(year: number, month: number): number {
  const firstDayOfMonth = new Date(year, month - 1, 1);
  const lastDayOfMonth = new Date(year, month, 0);

  const firstDayOfWeek = firstDayOfMonth.getDay();
  const lastDayOfWeek = lastDayOfMonth.getDay();

  const daysInMonth = lastDayOfMonth.getDate();

  const weeksInMonth = Math.ceil((daysInMonth + firstDayOfWeek) / 7);

  return weeksInMonth;
}
