import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil';
import { useGetCoachLectures } from '#api/lectures';
import { MinusCircle, PlusCircle } from '#assets/svg';
import { ModalPortal, Button, Text, Input } from '#atoms';
import { PATH } from '#const/path';
import { Modal } from '#molecules';
import { useUserInfo } from '#providers/UserInfo';
import { $applicationData } from '#states/atoms/applicationState';
import { $coachLectureState } from '#states/atoms/coachLectureState';
import { $confirmationFormState } from '#states/atoms/confirmationFormState';
import { Lecture } from '#types/lectures';
import { calculatePriceBySession } from '#utils/calculatePriceBySession';
import { formatNumberWithCommas } from '#utils/Numbers';
import { SelectBox } from './SelectBox';
import {
  Background,
  ApplyModalWrap,
  SelectArea,
  PriceArea,
  ButtonArea,
  CautionContainer,
  QuantityInput
} from './styles';
import { ISelectedLecture } from './types';

interface Props {
  setShowModal: SetterOrUpdater<boolean>;
  onApplyButtonClick?: () => void;
}

export const CoachDetailApplyModal = ({
  setShowModal,
  onApplyButtonClick
}: Props) => {
  const { myUrl } = useParams();
  if (!myUrl) return null;
  const coachLecturesState = useRecoilValue($coachLectureState);
  const setConfirmationFormState = useSetRecoilState($confirmationFormState);
  // const setShowLoginModal = useSetRecoilState($modalFamily(MODAL_TYPE.LOGIN));
  const [selectedLecture, setSelectedLecture] =
    useState<ISelectedLecture | null>(null);
  const unitPrice = coachLecturesState.find(
    lecture => lecture.id === selectedLecture?.id
  )?.price;
  const recommendRoundPerWeek = coachLecturesState.find(
    lecture => lecture.id === selectedLecture?.id
  )?.recommendRoundPerWeek;
  const round =
    coachLecturesState.find(lecture => lecture.id === selectedLecture?.id)
      ?.rounds ?? 1;
  const isOt = coachLecturesState.find(
    lecture => lecture.id === selectedLecture?.id
  )?.isOt;
  const isOnePoint = coachLecturesState.find(
    lecture => lecture.id === selectedLecture?.id
  )?.isOnePoint;
  const coachId = coachLecturesState[0]?.coachId;
  const gymId = coachLecturesState[0]?.gymId;
  const [price, setPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const { isLogIn } = useUserInfo();
  const navigate = useNavigate();
  const [cautionModal, setCautionModal] = useState<boolean>(false);
  const setApplicationData = useSetRecoilState($applicationData);
  const { data: lecturesData } = useGetCoachLectures(coachId);
  const currentLecture = lecturesData?.data.find(
    lecture => lecture.id === selectedLecture?.id
  );

  console.log(coachLecturesState);

  const handleBackgroundClick = () => {
    setShowModal(false);
  };

  const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleApplyButtonClick = () => {
    if (!selectedLecture || !coachId || !gymId) return;
    setConfirmationFormState(prev => ({
      ...prev,
      coachId,
      gymId,
      quantity,
      lectureId: selectedLecture.id,
      recommendRoundPerWeek: recommendRoundPerWeek ?? null,
      lectureName: selectedLecture.name ?? null,
      coachName: coachLecturesState[0]?.coachName ?? null,
      gymName: coachLecturesState[0]?.gymName ?? null,
      unitPrice: unitPrice ?? null
    }));
    onApplyButtonClick?.();
    if (isLogIn) {
      // 해당 부분을 통해서 Application에 강의 정보를 전달
      if (isOt && unitPrice === 0) return setCautionModal(true);
      setApplicationData({
        gymId,
        lectureId: selectedLecture.id,
        myUrl,
        price: price
      });
      navigate(PATH.PAYMENT, {
        state: { data: { gymId, lectureId: selectedLecture.id, myUrl, price } }
      });
    } else {
      setShowModal(false);
      // setShowLoginModal(true);
      navigate(PATH.SIGN, {
        state: { blockedPagePath: location.pathname + location.search }
      });
    }
  };

  const handleQuantityButtonClick = (type: string) => {
    if (type === 'plus') {
      quantity < 99 && setQuantity(prev => (prev += 1));
    }

    if (type === 'minus') {
      setQuantity(prev => (prev > 1 ? (prev -= 1) : prev));
    }
  };

  const onNextButtonClick = () => {
    setCautionModal(false);
    selectedLecture &&
      setApplicationData({
        gymId,
        lectureId: selectedLecture.id,
        myUrl,
        price
      });
    navigate(PATH.PAYMENT, {
      state: { data: { gymId, lectureId: selectedLecture?.id, myUrl } }
    });
  };

  const handleQuantityChange = (value: string) => {
    if (Number(value) >= 100) return setQuantity(100);
    setQuantity(Number(value));
  };

  useEffect(() => {
    setQuantity(isOt ? round : 1);
  }, [unitPrice, round, isOt]);

  useEffect(() => {
    if (unitPrice == undefined || currentLecture == undefined) return;
    const price = calculatePriceBySession(currentLecture, quantity, unitPrice);
    setPrice(price * quantity);
  }, [unitPrice, quantity, selectedLecture]);

  useEffect(() => {
    if (!currentLecture?.price) return;
    setDiscountPrice(currentLecture?.price * quantity - price);
  }, [price]);

  return (
    <ModalPortal>
      <Background onClick={handleBackgroundClick}>
        <ApplyModalWrap onClick={handleModalClick}>
          <SelectArea>
            <SelectBox
              placeholder="운동을 선택해주세요"
              data={coachLecturesState}
              selectedItemName={selectedLecture?.name}
              setSelectedItem={setSelectedLecture}
            />
            {unitPrice != undefined && (
              <PriceArea>
                <Text textStyle="t1">
                  <Text type="inline">{price.toLocaleString('ko-KR')}</Text>
                  <Text className="unit" type="inline">
                    원
                  </Text>
                  {currentLecture && discountPrice > 0 && (
                    <Text
                      type="inline"
                      textStyle="t3"
                      textColor="gray"
                      className="discount"
                    >
                      ({formatNumberWithCommas(discountPrice)}원 할인)
                    </Text>
                  )}
                </Text>
                {isOt || isOnePoint ? (
                  <ButtonArea>
                    <MinusCircle />
                    <Text textStyle="t3">{round}</Text>
                    <PlusCircle />
                  </ButtonArea>
                ) : (
                  <ButtonArea>
                    <MinusCircle
                      onClick={() => handleQuantityButtonClick('minus')}
                    />
                    <QuantityInput>
                      <Input
                        type={'string'}
                        value={quantity}
                        onChange={v => handleQuantityChange(v.toString())}
                      />
                    </QuantityInput>
                    <PlusCircle
                      onClick={() => handleQuantityButtonClick('plus')}
                    />
                  </ButtonArea>
                )}
              </PriceArea>
            )}
          </SelectArea>
          <Button onClick={handleApplyButtonClick}>신청하기</Button>
        </ApplyModalWrap>
        {cautionModal && (
          <Modal onClose={() => setCautionModal(false)}>
            <CautionContainer>
              <Text>
                무료 PT를 신청하시는 경우
                <br />
                수업 종료 후 피벗 내 리뷰 작성이 필요하며,
                <br />
                수업에 노쇼(NoShow)하실 경우
                <br />
                이후 무료 PT 신청이 제한됩니다.
              </Text>
            </CautionContainer>
            <Button onClick={onNextButtonClick}>확인</Button>
          </Modal>
        )}
      </Background>
    </ModalPortal>
  );
};
