import { useState } from 'react';
import { AltImage } from '#assets/svg';
import {
  ProfileViewerWrap,
  ImageList,
  ImageItem,
  IndexIconArea,
  IndexIcon
} from './styles';

interface Props {
  imgSrcList: string[];
  handleImageLoaded?: () => void;
}

export const ProfileViewer = ({ imgSrcList, handleImageLoaded }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleScroll: React.UIEventHandler<HTMLUListElement> = e => {
    const newIndex = Math.round(
      e.currentTarget.scrollLeft / e.currentTarget.clientWidth
    );
    setCurrentIndex(newIndex);
  };
  const isLazyImage = (idx: number) => (idx !== 0 ? 'lazy' : 'eager');
  return (
    <ProfileViewerWrap>
      <ImageList onScroll={handleScroll}>
        {imgSrcList.length ? (
          imgSrcList.map((imgSrc, idx) => (
            <ImageItem key={imgSrc}>
              <img
                onLoad={handleImageLoaded}
                src={imgSrc}
                loading={isLazyImage(idx)}
                alt="profile img"
              />
            </ImageItem>
          ))
        ) : (
          <ImageItem className="alt">
            <AltImage />
          </ImageItem>
        )}
      </ImageList>
      <IndexIconArea>
        {new Array(imgSrcList.length).fill(null).map((_, index) => (
          <IndexIcon
            key={index}
            className={index === currentIndex ? 'point' : ''}
          />
        ))}
      </IndexIconArea>
    </ProfileViewerWrap>
  );
};
