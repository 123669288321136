import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import logo from '#assets/svg/logo.svg';
import { BoxIcon, Text } from '#atoms';
import { PATH } from '#const/path';
import { KakaoButton } from '#molecules';
import sessionStorageManager from '#utils/sessionStorageManager';
import {
  Container,
  LogoArea,
  ButtonArea,
  EmailAuthButtonArea,
  OAuthButtonArea,
  LogoWrapper
} from './styles';

export function SignHomePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setBlockedPagePath } = sessionStorageManager();

  const emailSignInCallback = useCallback(
    () =>
      navigate(PATH.SIGNIN, {
        state: { blockedPagePath: location.state?.blockedPagePath }
      }),
    []
  );

  const emailSignUpCallback = useCallback(() => {
    setBlockedPagePath(location.state?.blockedPagePath ?? '');
    navigate(PATH.SIGNUP);
  }, []);

  return (
    <Container>
      <LogoWrapper>
        <BoxIcon
          type="x-white"
          onlyIcon
          size={18}
          onClick={() => navigate(-1)}
        />
        <LogoArea>
          <img src={logo} />
          <Text
            textStyle="t3"
            style={{ textAlign: 'center', fontWeight: '600' }}
          >
            숫자만 세주는 트레이너 말고,<br></br> 진짜 전문가를 만나는 곳.
          </Text>
        </LogoArea>
      </LogoWrapper>
      <ButtonArea>
        <OAuthButtonArea>
          <KakaoButton
            text="카카오로 1초만에 시작하기"
            icon
            onClick={() => {
              setBlockedPagePath(location.state?.blockedPagePath ?? '');
              window.location.href = `${process.env.REACT_APP_API_SERVER_URL}/auth/login/kakao?state=fibud`;
            }}
          />
        </OAuthButtonArea>
        <EmailAuthButtonArea>
          <Text textStyle="c1" onClick={emailSignInCallback}>
            이메일로 로그인
          </Text>
          <div style={{ opacity: 0.5 }}></div>
          <Text textStyle="c1" onClick={emailSignUpCallback}>
            이메일로 회원가입
          </Text>
        </EmailAuthButtonArea>
      </ButtonArea>
    </Container>
  );
}
