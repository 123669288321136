// useWeeklyCalender.ts
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useGetCoachSchedulePerWeek } from '#api/schedules';
import { CurrentDate } from '#types/date';
import { yearMonthDayFormatter } from '#utils/dateFormatter';
import { useCalendar } from 'src/components/molecules/FibudCalendar/hooks';

interface WeekDataType {
  day: string;
  date: number;
}

function useWeeklyCalender() {
  const { currentMonth, today } = useCalendar({ schedule: undefined });
  const date = dayjs(today.dayjs);
  const currentDayOfWeek = date.day();
  const dayOfWeek = date.format('dddd');
  const startOfWeek = date.subtract(currentDayOfWeek, 'day');
  const startOfWeekDate = startOfWeek.format('YYYY-MM-DD');
  const [currentDate, setCurrentDate] = useState<CurrentDate>({
    year: today.year,
    month: today.month,
    week: 0
  });
  const { data: weekData } = useGetCoachSchedulePerWeek({
    startAt: startOfWeekDate,
    durationDays: 7
  });

  const formatWeekData = weekData?.map(item => ({
    ...item,
    date: yearMonthDayFormatter(item.date)
  }));

  const weekDayAndDates: WeekDataType[] = useMemo(() => {
    return Array.from({ length: 7 }).map((_, index) => ({
      day: startOfWeek.add(index, 'day').format('dddd'),
      date: startOfWeek.add(index, 'day').date()
    }));
  }, [startOfWeek]);

  return {
    currentDate,
    setCurrentDate,
    currentMonth,
    dayOfWeek,
    weekDayAndDates,
    formatWeekData
  };
}

export default useWeeklyCalender;
