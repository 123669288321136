import classNames from 'classnames';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { BoxIcon, Button, Text } from '#atoms';
import { PATH } from '#const/path';
import { TOTAL_POLICY_LIST } from '#const/policy/list';
import { useCheckValidState } from '#hooks/useCheckValidState';
import { PolicyModal } from '#organisims';
import { $signupState } from '#states/atoms/recoilSignupState';
import { PrevWithTitleTextTemplate } from '#templates';
import {
  ButtonContainer,
  CheckBox,
  CheckListContainer,
  Container
} from './styles';

export function SignUpPolicy() {
  const [signupState, setSignupState] = useRecoilState($signupState);
  const navigate = useNavigate();

  const [checklistItems, setChecklistItems] = useState(
    TOTAL_POLICY_LIST.map(policy => ({
      id: policy.id,
      label: policy.text,
      required: policy.isEssential,
      detailed: policy.hasMoreDetail,
      checked: signupState.selectedPolicies?.includes(policy.id)
    }))
  );
  const [selectAll, setSelectAll] = useState(
    checklistItems.every(item => item.checked)
  );
  const [policyDetailModal, setPolicyDetailModal] = useState<string>('');

  useCheckValidState({
    targetState: typeof signupState.isCoach === 'boolean',
    navigateTo: PATH.SIGN_UP_CATEGORY
  });

  const handleCheckboxChange = (itemId: string) => {
    const updatedChecklistItems = checklistItems.map(item => {
      if (item.id === itemId) {
        item.checked = !item.checked;
      }
      return item;
    });
    setChecklistItems(updatedChecklistItems);

    const isAnyUnchecked = updatedChecklistItems.some(item => !item.checked);
    setSelectAll(!isAnyUnchecked);
  };

  const handleSelectAllChange = () => {
    const updatedChecklistItems = checklistItems.map(item => ({
      ...item,
      checked: !selectAll
    }));

    setChecklistItems(updatedChecklistItems);
    setSelectAll(!selectAll);
  };

  // 필수 항목 중 하나라도 체크되지 않은 경우 확인 버튼 비활성화
  const isChecklistInvalid = checklistItems.some(
    item => item.required && !item.checked
  );

  const handleOptionalPolicyCheck = () => {
    const selectedPolicies = checklistItems
      .filter(item => item.checked)
      .map(item => item.id);

    setSignupState(prev => ({
      ...prev,
      selectedPolicies: selectedPolicies,
      agreeNotification:
        checklistItems.find(e => e.required === false)?.checked ?? false
    }));
  };

  return (
    <PrevWithTitleTextTemplate
      prevRoute={PATH.SIGN_UP_CATEGORY}
      title={
        <Text textStyle="display">
          서비스 이용을 위해
          <br />
          약관에 동의해주세요
        </Text>
      }
    >
      <Container>
        <CheckListContainer onClick={handleSelectAllChange}>
          <CheckBox className={classNames({ checked: selectAll })}>
            {selectAll && (
              <BoxIcon type="check-white" size={10} boxColor="transparent" />
            )}
          </CheckBox>
          <Text textStyle="t3">전체동의</Text>
        </CheckListContainer>
        {checklistItems.map(item => (
          <CheckListContainer
            key={item.id}
            className="checklist"
            onClick={() => handleCheckboxChange(item.id)}
          >
            <CheckBox className={classNames({ checked: item.checked })}>
              {item.checked && (
                <BoxIcon type="check-white" size={10} boxColor="transparent" />
              )}
            </CheckBox>
            <Text>{item.label}</Text>
            <div>
              <Text
                textStyle="c2"
                textColor={item.required ? 'point' : 'gray2'}
              >
                {item.required ? '(필수)' : '(선택)'}
              </Text>
              {item.detailed && (
                <BoxIcon
                  type="next-arrow"
                  onlyIcon
                  size={14}
                  onClick={() => setPolicyDetailModal(item.id)}
                />
              )}
            </div>
          </CheckListContainer>
        ))}
      </Container>
      {policyDetailModal && (
        <PolicyModal
          type={policyDetailModal}
          onCancel={() => {
            setPolicyDetailModal('');
          }}
        />
      )}
      <ButtonContainer>
        <Button
          disabled={isChecklistInvalid}
          onClick={() => {
            handleOptionalPolicyCheck();
            navigate(PATH.SIGN_UP_PHONE);
          }}
        >
          다음
        </Button>
      </ButtonContainer>
    </PrevWithTitleTextTemplate>
  );
}
