import React, { PropsWithChildren } from 'react';
import { Container, TitleConatiner, TopArticle, ContentArticle } from './style';

interface Props {
  TitleLeft?: React.ReactNode;
  TitleCenter?: React.ReactNode;
  TitleRight?: React.ReactNode;
}

export const HeaderBarTemplate = ({
  TitleLeft,
  TitleCenter,
  TitleRight,
  children
}: PropsWithChildren<Props>) => {
  return (
    <Container>
      <TopArticle>
        <TitleConatiner>
          <div>{TitleLeft}</div>
          <div className="center">{TitleCenter}</div>
          <div>{TitleRight}</div>
        </TitleConatiner>
      </TopArticle>
      <ContentArticle>{children}</ContentArticle>
    </Container>
  );
};
