import { Spacing, Text } from '#atoms';
import { CategorySelector, LocationItem, PriceModal } from '#molecules';
import { CoachGridSelector } from '#organisims';
import { $modalState } from '#states/atoms/modalState';
import { $selectedCoachTagState } from '#states/atoms/tagState';
import MetaHelmet from '#utils/MetaHelmet';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CategorySelectorContainer, Container } from './styles';

export const CoachPage = () => {
  const [showModal, setShowModal] = useRecoilState($modalState);
  const selectedCoachTag = useRecoilValue($selectedCoachTagState);

  return (
    <>
      <MetaHelmet title="피벗 내 주변 트레이너 찾기" />
      <Container>
        <LocationItem />
        <Spacing size={20} />
        <CategorySelectorContainer>
          <CategorySelector />
        </CategorySelectorContainer>
        <header>
          <Text textStyle="display">
            {selectedCoachTag.name} 전문가를
            {/* {selectedCoachTag.id === 'all' ? ' ' : ' 전문가 '}전문가를 */}
          </Text>
          <Text textStyle="display">소개해 드릴게요!</Text>
        </header>
        <CoachGridSelector />
        {showModal && <PriceModal setShowModal={setShowModal} />}
      </Container>
    </>
  );
};
