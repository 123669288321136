import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { IGetCoachSchedules } from '#types/api';
import { dayJsToFibudDate } from '../../../providers/Schedules/utils';
import { CaldenderRecipe, CalendarDay, FibudDate } from './types';

interface Props {
  schedule?: IGetCoachSchedules;
}

export const useCalendar = ({ schedule }: Props) => {
  const [today] = useState<FibudDate>(dayJsToFibudDate(dayjs()));
  const [monthOffset, setMonthOffset] = useState<number>(0);
  const [currentMonth, setCurrentMonth] = useState<FibudDate>(today);
  const [currentDateTable, setCurrentDateTable] = useState<FibudDate[]>([]);

  const nextMonth = useCallback(
    () => setMonthOffset(prev => prev + 1),
    [monthOffset]
  );

  const prevMonth = useCallback(
    () => setMonthOffset(prev => Math.max(0, prev - 1)),
    [monthOffset]
  );

  useEffect(() => {
    const startOfMonth = today.dayjs.add(monthOffset, 'month').set('date', 1);
    const startIdx = startOfMonth.day();
    // 공백이 포함되어서 오는 케이스가 존재하는듯?
    const avaliableChunk = ['1'];
    const nextDateTable: FibudDate[] = [];

    for (let i = 0; i < startIdx; i++) {
      nextDateTable.push(
        dayJsToFibudDate(startOfMonth.subtract(startIdx - i, 'd'))
      );
    }

    for (let i = startIdx; i < 35; i++) {
      nextDateTable.push(dayJsToFibudDate(startOfMonth.add(i - startIdx, 'd')));
    }

    // 지금 보고 있는 달인 경우만 처리
    if (!monthOffset) {
      nextDateTable.forEach(date => {
        if (date.time < today.time) date.disabled = true;
      });
    }

    // 수강 불가능한 날짜
    // nextDateTable.forEach((date, idx) => {
    //   if (!avaliableChunk.includes(idx % 7)) date.disabled = true;
    // });

    setCurrentMonth(dayJsToFibudDate(startOfMonth));
    setCurrentDateTable(nextDateTable);
  }, [monthOffset]);

  useEffect(() => {
    if (!currentDateTable[0]) return;

    // schedulesDate.reduce<Set<number>>((acc, { startAt }) => {
    //   const startDay = dayjs(startAt);
    //   const v = currentDateTable[0].dayjs.diff(startDay, 'D');
    //   console.log(v);
    //   return acc;
    // }, new Set<number>());
  }, [currentDateTable]);

  return {
    today,
    monthOffset,
    currentMonth,
    currentDateTable,
    nextMonth,
    prevMonth
  };
};
