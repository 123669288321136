export const TrainerBannerText = {
  profile: {
    top_text: '수업 개설을 위해',
    bottom_text: '프로필을 등록해주세요',
    title: '프로필 등록하기'
  },
  settlement: {
    top_text: '진행하신 수업이 있으신가요?',
    bottom_text: '계좌정보를 등록하고 수입을 정산받으세요',
    title: '수입 관리하기'
  }
};
