import classNames from 'classnames';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { MainBottomSheet } from '#organisims';
import UserInfo, { useUserInfo } from '#providers/UserInfo';
import { MainConsumerTemplate, MainTrainerTemplate } from '#templates';
import MetaHelmet from '#utils/MetaHelmet';
import { BusinessInfo } from './BusinessInfo';
import { Container, MainHomeContainer } from './styles';

export const MainHomePage = () => {
  const { userInfo } = useUserInfo();

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'view_main'
      }
    };

    TagManager.dataLayer(tagManagerArgs);
  }, []);

  return (
    <MainHomeContainer isLoggedUser={!!userInfo}>
      <MetaHelmet title="피벗" />
      <Container
        className={classNames({
          trainer: userInfo && userInfo.isCoach
        })}
      >
        <UserInfo.ConsumerLike>
          <MainConsumerTemplate />
        </UserInfo.ConsumerLike>
        <UserInfo.IsCoach>
          <MainTrainerTemplate />
        </UserInfo.IsCoach>
        <BusinessInfo />
      </Container>
      {!(userInfo && userInfo.isCoach) && <MainBottomSheet />}
    </MainHomeContainer>
  );
};
