import { useNavigate } from 'react-router';
import alt_image from '#assets/svg/alt_image.svg';
import { Text } from '#atoms';
import { IGetClinicalData } from '#types/clinicalData';
import { ImageOrVideoRenderer } from '../ClinicalDataItem';
import { AltImageBox } from '../GymLocationBottomSheet/styles';
import {
  DataContainer,
  ImageContainer,
  PreviewContainer,
  PreviewTitleContainer
} from './styles';

interface Props {
  data?: IGetClinicalData[];
}

export const ClinicalDataList = ({ data }: Props) => {
  const navigate = useNavigate();

  return (
    <DataContainer>
      <Text textColor="gray2">최근 포트폴리오</Text>
      <div>
        {data?.map(data => (
          <PreviewContainer
            key={data.id}
            onClick={() => navigate(`/expert/${data.myUrl}`)}
          >
            <PreviewTitleContainer>
              <Text textStyle="bb">{data.lectureName}</Text>
              {/* lectureName 최대 1줄 */}
              <Text textStyle="c2" textColor="gray2">
                {data.coachName} 전문가
              </Text>
            </PreviewTitleContainer>
            <ImageContainer className="preview">
              <div>
                <ImageOrVideoRenderer url={data.beforeImages[0]?.resizedLink} />
                <p className="before">전</p>
                <ImageOrVideoRenderer url={data.afterImages[0]?.resizedLink} />
                <p className="after">후</p>
              </div>
            </ImageContainer>
            {/* body 최대 3줄 */}
            <Text textStyle="c1" textColor="gray2">
              {data.body}
            </Text>
          </PreviewContainer>
        ))}
      </div>
    </DataContainer>
  );
};
