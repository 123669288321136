import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 3rem 2rem;

  & > header {
    padding: 3.2rem 0;
  }
`;

export const CategorySelectorContainer = styled.div`
  width: 100%;
  height: 89px;
`;
