import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSwipeable } from 'react-swipeable';
import { BoxIcon, Text } from '#atoms';
import { PATH } from '#const/path';
import { DaySelectModal, Modal, TimeLine } from '#molecules';
import { useScheduleManage } from '#providers/SchedulesManage';
import { getNextSunday } from '#providers/SchedulesManage/utils';
import { ReservedMembersType } from '#types/schedule';
import { dayOfWeekMap } from './constant';
import useWeeklyCalender from './hook/useWeeklyCalender';
import {
  Container,
  WeekLists,
  DateArea,
  WeekCalenderContainer,
  DayLi,
  Header
} from './styles';

export interface WeekDataType {
  day: string;
  date: string;
}
interface WeekListProps {
  today: string;
}
const initialLecture: ReservedMembersType = {
  id: '',
  isExistExerciseRecord: '',
  lectureName: '',
  lectureId: '',
  personId: '',
  personName: '',
  startAt: '',
  endedAt: '',
  taughtAt: '',
  round: 0,
  totalRound: 0,
  title: '',
  repetition: {
    type: 'WEEKLY',
    interval: 1,
    daysOfWeek: [0, 1, 3]
  },
  gymId: '',
  memo: ''
};

export function WeeklyCalender() {
  const { currentDate, weekDayAndDates, startDateWeek, setSelectedLecture } =
    useScheduleManage();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { dayOfWeek } = useWeeklyCalender();
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <Header>
          <DateArea onClick={() => setModalOpen(prev => !prev)}>
            <Text textStyle="t2">
              {`${currentDate.year}년  ${currentDate.month}월`}
            </Text>
            <BoxIcon
              type="prev-arrow"
              boxColor="transparent"
              size={18}
              onlyIcon={true}
            />
          </DateArea>
          <BoxIcon
            size={24}
            type={'plus-white'}
            boxColor="transparent"
            onClick={() => {
              navigate(PATH.Weekly_Calender_Schedule_Registration);
              setSelectedLecture(initialLecture);
            }}
          />
        </Header>
        <WeekCalenderContainer>
          <WeekList today={dayOfWeek} />
        </WeekCalenderContainer>
        {modalOpen && <DaySelectModal onClose={() => setModalOpen(false)} />}
      </Container>
    </>
  );
}

function WeekList({ today }: WeekListProps) {
  const { currentDate, setCurrentDate, weekDayAndDates, startDateWeek } =
    useScheduleManage();

  const handleSwipe = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      const nextWeek = getNextSunday(startDateWeek);
    }
    if (direction === 'prev') {
    }
  };
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('next'),
    onSwipedRight: () => handleSwipe('prev'),
    trackMouse: true
  });
  return (
    <>
      <WeekLists>
        {weekDayAndDates.map((day, index) => (
          <DayLi key={index} isActive={today === day.day ? true : false}>
            <Text>{day.day[0]}</Text>
            <Text>{dayjs(day.date).date()}</Text>
          </DayLi>
        ))}
      </WeekLists>
      <TimeLine />
    </>
  );
}
