import { tab } from '@testing-library/user-event/dist/tab';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import useScrollRestoration from '#hooks/useScrollRestoration';
import { TabListViewer } from '#molecules';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { TabIntroduce } from 'src/components/molecules/TabIntroduce';
import { CoacheList } from 'src/components/organisims/CoacheList/CoacheList';
import { ProfileViewer } from 'src/components/templates/CoachDetailContent/ProfileViewer';
import {
  useGetEducationCoach,
  useGetEducationDetail,
  useGetEducationIssuerId
} from 'src/domain/education/api';
import { EduCoachesList } from 'src/domain/education/components/EduCoachList';
import { EduIntroduce } from 'src/domain/education/components/EduIntroduce';
import { IEducation } from 'src/domain/education/types';

export const EduPage = () => {
  // 쿼리 파라미터 가져오기
  const { educationUrl } = useParams();

  const { data: educationIssuerId } = useGetEducationIssuerId(educationUrl, {
    enabled: !!educationUrl
  });
  const { data: educationDetail } = useGetEducationDetail(
    educationIssuerId as string,
    { enabled: !!educationIssuerId }
  );

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const onTabChange = (tab: number) => {
    const searchParams = new URLSearchParams();
    searchParams.set('tab', tab.toString());
    navigate(
      {
        search: searchParams.toString()
      },
      {
        replace: true
      }
    );
  };

  return (
    <PrevHeaderBarWithTitleTemplate title={educationDetail?.name ?? '협회'}>
      {educationDetail?.images && (
        <ProfileViewer
          imgSrcList={educationDetail?.images.map(image => image.uploadedLink)}
        ></ProfileViewer>
      )}
      <TabListViewer initTab={tab ? Number(tab) : 0} onTabChange={onTabChange}>
        <TabListViewer.List tabs={['소개', '수료 전문가']} />
        <TabListViewer.Viewer
          tabs={[
            <>
              {educationDetail && (
                <EduIntroduce educationDetail={educationDetail} />
              )}
            </>,
            <EduCoachesList />
          ]}
        />
      </TabListViewer>
    </PrevHeaderBarWithTitleTemplate>
  );
};
