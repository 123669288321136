import { Helmet } from 'react-helmet-async';

function MetaHelmet({
  title,
  description,
  keywords,
  url,
  img
}: {
  title: string;
  description: string;
  keywords: string;
  url: string;
  img: string;
}) {
  if (process.env.REACT_APP_NODE_ENV !== 'production') return <></>;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={img} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`${url}/`} />
      <meta name="keywords" content={keywords} />
      {/* <meta property="al:ios:url" content="fibud://app" />
      <meta property="al:ios:app_store_id" content="1553110119" />
      <meta property="al:ios:app_name" content="피벗" />
      <meta property="al:android:url" content="fibud://app" />
      <meta property="al:android:package" content="kr.fibud.app" />
      <meta property="al:android:app_name" content="피벗" /> */}

      <meta property="al:web:url" content={`${url}/`} />
    </Helmet>
  );
}

// description에 두개의 요소를 적용시 배열로 적용하거나 하나의 스트링으로 처리해야합니다.
MetaHelmet.defaultProps = {
  title: '피벗',
  description: '숫자만 세주는 전문가 말고 진짜 전문가, PT는 피벗',
  keywords:
    '피벗, Fibud, 전문가, PT, 퍼스널 트레이닝, 다이어트, 체형교정, 재활, 바디프로필, 바디빌딩, 보디빌딩, 통증, 실버, 기능성, 검증, 실력',
  url: 'https://fibud.kr/',
  img: './ms-icon-70x70.png'
};

export default MetaHelmet;
