import {
  CoachPage,
  LectureChartPage,
  MainHomePage,
  MainIntroductionPage
} from '#pages';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { TabContainer } from 'src/components/molecules/TabListViewer/styles';

export const MainConsumerView = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const mainViewUrlList = [
    '/home/introduce',
    '/',
    '/home/expert',
    '/home/chart'
  ];

  const [currentTab, setCurrentTab] = useState(-1);

  useEffect(() => {
    const tabIndex = mainViewUrlList.findIndex(url => {
      // 프리렌더링된 화면이 있을경우 pathname + '/'로 리다이렉팅됨
      const notRedirectPathname = pathname.endsWith('/')
        ? pathname.slice(0, -1)
        : pathname;

      if (notRedirectPathname === '/home' && url === '/') {
        return true;
      }

      return url === notRedirectPathname;
    });

    setCurrentTab(tabIndex);
  });

  const tabs = [
    { title: '소개', url: 'introduce' },
    { title: '홈', url: '' },
    { title: '전문가', url: 'expert' },
    { title: '운동차트', url: 'chart' }
  ];

  return (
    <>
      <TabContainer>
        {tabs.map((tab, idx) => (
          <li
            key={tab.title}
            className={classNames({ on: idx === currentTab })}
            data-text-type={idx === currentTab ? 't3' : undefined}
          >
            <Link to={`/home/${tab.url}`}>{tab.title}</Link>
          </li>
        ))}
      </TabContainer>
      <Routes>
        <Route path="/introduce" element={<MainIntroductionPage />} />
        <Route path="/" element={<MainHomePage />} />
        <Route path="/expert" element={<CoachPage />} />
        <Route path="/chart" element={<LectureChartPage />} />
      </Routes>
    </>
  );
};
