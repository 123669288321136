import { useRecoilState, useSetRecoilState } from 'recoil';
import { Text } from '#atoms';
import { COACH_FILTER } from '#const/coach/filter';
import {
  $coachConditionState,
  ICoachConditionState
} from '#states/atoms/coachCondition';
import { $modalState } from '#states/atoms/modalState';
import { CoachFilterWrap, FilterItem } from './styles';

export const CoachFilter = () => {
  const setShowModal = useSetRecoilState($modalState);
  const [coachConditionState, setCoachConditionState] =
    useRecoilState($coachConditionState);

  const handleItemClick = (code: string) => {
    switch (code) {
      case COACH_FILTER[0].code:
        setCoachConditionState(prev => {
          return {
            ...prev,
            grade: false,
            reviewCount: false,
            distance: true,
            price: false,
            minPrice: undefined,
            maxPrice: undefined
          };
        });
        break;

      case COACH_FILTER[1].code:
        setCoachConditionState(prev => {
          return {
            ...prev,
            grade: true,
            reviewCount: false,
            distance: false,
            price: false,
            minPrice: undefined,
            maxPrice: undefined
          };
        });
        break;

      case COACH_FILTER[2].code:
        setCoachConditionState(prev => {
          return {
            ...prev,
            grade: false,
            reviewCount: true,
            distance: false,
            price: false,
            minPrice: undefined,
            maxPrice: undefined
          };
        });
        break;

      case COACH_FILTER[3].code:
        setShowModal(true);
        break;

      default:
        break;
    }
  };

  return (
    <CoachFilterWrap>
      {COACH_FILTER.map(item => (
        <FilterItem
          key={item.code}
          className={
            coachConditionState[item.code as keyof ICoachConditionState]
              ? 'select'
              : ''
          }
          onClick={() => handleItemClick(item.code)}
        >
          <Text textStyle="c1">{item.name}</Text>
        </FilterItem>
      ))}
    </CoachFilterWrap>
  );
};
